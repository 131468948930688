import React, { useEffect, useState } from "react";
import { Token } from "src/interfaces/token";
import AdminApi from "src/api/admin";
import TokensTable from "src/components/tokens/TokensTable";
import NoToken from "src/components/tokens/NoToken";
import useAdminRedirect from "src/hooks/private/useAdminRedirect";
import { Navigate } from "react-router-dom";

const Tokens = () => {
  const [tokens, setTokens] = useState<Token[]>([]);

  const fetchTokens = async () => {
    const resp = await AdminApi.getTokens();
    setTokens(resp);
  };

  useEffect(() => {
    fetchTokens();
  }, []);

  const redirectPath = useAdminRedirect();

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  return (
    <>
      {tokens.length ? (
        <TokensTable tokens={tokens} fetchTokens={fetchTokens} />
      ) : (
        <NoToken />
      )}
    </>
  );
};

export default Tokens;
