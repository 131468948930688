import React, { useState } from "react";
import { TeamPageData } from "src/interfaces/TeamPageData";
import useModal from "src/hooks/useModal";
import Button from "../buttons/Button";
import FuseCoin from "../icons/FuseCoin";
import { adminApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import FloatingInput from "../fields/FloatingInputText";
import { capitalize } from "src/helpers/parseStrings";
import useAdminContext from "src/hooks/private/useAdminContext";

interface AdjustBalanceProps {
  team: TeamPageData;
  fetchTeam: () => void;
  fetchNotes: () => void;
}

const AdjustBalance = ({ team, fetchTeam, fetchNotes }: AdjustBalanceProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const { admin } = useAdminContext();

  const { id, leaderID, companyName, coinsPurchased, coinsSubscription } = team.team;

  interface FormErrors {
    coinAmount: string | null;
    teamNote: string | null;
  }

  const initialFormErrors: FormErrors = {
    coinAmount: "",
    teamNote: "",
  };

  interface FormData {
    coinAmount: number;
    teamNote: string;
    type: "credit" | "debit";
  }

  const initialFormData: FormData = {
    coinAmount: 0,
    teamNote: "",
    type: "credit",
  };

  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const validate: any = (data: any) => {
    const errors: {
      coinAmount?: string;
      teamNote?: string;
    } = {};
    if (!data.teamNote.length) {
      errors.teamNote = "Please write a Team Note about this transaction";
    }
    if (
      data.type === "debit" &&
      data.coinAmount > coinsPurchased + coinsSubscription
    ) {
      errors.coinAmount = "Coins to debit is greater than balance";
    }
    return errors;
  };
  

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // As both radio inputs have the same name 'type',
    // we can set the formData based on the value attribute of the selected radio input.
    if (name === "type") {
      setFormData({ ...formData, type: value as "credit" | "debit" });
    }
  };

  const handleSubmit = async () => {
    setFormErrors(initialFormErrors);
    let errors = validate(formData);
    const { coinAmount, type } = formData;
    if (errors.coinAmount || errors.teamNote) {
      return setFormErrors(errors);
    } else {
      setOnSubmitting(true);
      try {
        await adminApi.adjustBalance({
          type,
          teamID: id,
          userID: leaderID,
          amount: coinAmount,
        });
        await adminApi.addTeamNote(id, {
          author: `${admin?.firstName} ${admin?.lastName}`,
          note: formData.teamNote,
        });
        setAlert({
          display: true,
          type: "success",
          message: `${companyName} was ${type}ed ${coinAmount} coins`,
        });
      } catch (e: any) {
        console.log(e);
        setAlert({
          display: true,
          type: "error",
          message: e.message || `There was an error ${type}ing coins`,
        });
      } finally {
        fetchTeam();
        fetchNotes();
      }
      setOnSubmitting(false);
      setShowModal(false);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className="mb-4 flex items-center justify-center gap-3">
        <div className="inline-block">
          <span className="text-zinc-400">Purchased</span>
          <span className="flex h-8  select-none items-center justify-center space-x-1 rounded-lg border border-zinc-500 bg-card-light px-1 shadow-lg drop-shadow-lg dark:bg-card-dark">
            <FuseCoin />
            <span className="select-none text-sm font-extrabold text-text-dark dark:text-text-light">
              {coinsPurchased}
            </span>
          </span>
        </div>

        <div className="inline-block">
          <span className="text-zinc-400">Subscription</span>
          <span className="flex h-8  select-none items-center justify-center space-x-1 rounded-lg border border-zinc-500 bg-card-light px-1 shadow-lg drop-shadow-lg dark:bg-card-dark">
            <FuseCoin />
            <span className="select-none text-sm font-extrabold text-text-dark dark:text-text-light">
              {coinsSubscription}
            </span>
          </span>
        </div>
      </div>
      <div className="mb-4 flex items-center justify-center gap-4">
        <div className="form-control">
          <label className="label cursor-pointer gap-1">
            <input
              type="radio"
              name="type"
              value="credit"
              className="radio checked:bg-blue-500"
              checked={formData.type === "credit"}
              onChange={handleTypeChange}
            />
            <span className="label-text">Credit</span>
          </label>
        </div>
        <div className="form-control">
          <label className="label cursor-pointer gap-1">
            <input
              type="radio"
              name="type"
              value="debit"
              className="radio checked:bg-red-500"
              checked={formData.type === "debit"}
              onChange={handleTypeChange}
            />
            <span className="label-text">Debit</span>
          </label>
        </div>
      </div>
      <div className="mb-4">
        <input
          type="number"
          placeholder="Coin Amount"
          name="coinAmount"
          className="input-bordered input w-32 bg-card-light dark:bg-card-dark"
          onChange={handleChange}
          value={formData.coinAmount}
          // error={formData.coinAmount}
        />
      </div>
      <div className="mx-4 mb-2">
        <FloatingInput
          name={"teamNote"}
          label={"Note"}
          type={"text"}
          onChange={handleChange}
          value={formData.teamNote}
          error={formErrors.teamNote}
        />
      </div>
      <div className="flex items-center justify-center">
        <Button
          className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
          onClick={handleSubmit}
          disabled={onSubmitting}
          loading={onSubmitting}
        >
          {capitalize(formData.type)}
        </Button>
      </div>
    </div>
  );
};

export default AdjustBalance;
