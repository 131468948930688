import React, { useState } from "react";
import { isValidURL } from "src/utils/common";
import TextInput from "../fields/TextInput";
import Button from "../buttons/Button";
import useModal from "src/hooks/useModal";
import SelectInput from "../fields/SelectInput";
import States from "src/data/States";
import { adminApi } from "src/api";
import StripeForm from "src/components/stripe/StripeForm";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { withStripeProvider } from "src/hooks/useStripeOptions";
import useAlert from "src/hooks/useAlert";
import generatePassword from "src/helpers/generatePassword";

interface NewTeamOnboardingModalProps {
  _keapID?: number;
  _email?: string;
  _subscription?: "lite" | "pro" | "platinum";
  callBack?: () => {};
}

const NewTeamOnboardingModal = ({
  _keapID,
  _email,
  _subscription,
  callBack,
}: NewTeamOnboardingModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const stripe = useStripe();

  const elements = useElements();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const createCardToken = async (nameOnCard: string): Promise<any> => {
    if (!stripe || !elements) {
      throw new Error("Stripe not loaded");
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) throw new Error("Stripe element not found");

    const { error, token } = await stripe.createToken(cardElement, {
      name: nameOnCard,
    });
    if (error) throw new Error(error.message);

    return token;
  };

  interface OnboardingFormData {
    appID: number;
    subscription: "lite" | "pro" | "platinum";
    keapID: string;
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    companyWebsite: string;
    returnAddressLine1: string;
    returnAddressLine2: string;
    returnAddressCity: string;
    returnAddressState: string;
    returnAddressZip: string;
    returnPhoneNumber: string;
    isAnnualSubscription: string;
  }

  const initialFormData: OnboardingFormData = {
    appID: 1,
    subscription: _subscription ? _subscription : "platinum",
    keapID: _keapID ? _keapID.toString() : "",
    firstName: "",
    lastName: "",
    email: _email ? _email : "",
    companyName: "",
    companyWebsite: "",
    returnAddressLine1: "",
    returnAddressLine2: "",
    returnAddressCity: "",
    returnAddressState: "",
    returnAddressZip: "",
    returnPhoneNumber: "",
    isAnnualSubscription: "monthly",
  };

  const [formData, setFormData] = useState<OnboardingFormData>(initialFormData);

  interface FormErrors {
    subscription: string | null;
    keapID: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    companyName: string | null;
    companyWebsite: string | null;
    returnAddressLine1: string | null;
    returnAddressLine2: string | null;
    returnAddressCity: string | null;
    returnAddressState: string | null;
    returnAddressZip: string | null;
    returnPhoneNumber: string | null;
  }

  const initialFormErrors: FormErrors = {
    subscription: null,
    keapID: null,
    firstName: null,
    lastName: null,
    email: null,
    companyName: null,
    companyWebsite: null,
    returnAddressLine1: null,
    returnAddressLine2: null,
    returnAddressCity: null,
    returnAddressState: null,
    returnAddressZip: null,
    returnPhoneNumber: null,
  };

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const isInputNotANumber = (string: string) => {
    return isNaN(Number(string));
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "returnPhoneNumber" && isInputNotANumber(e.nativeEvent.data)) {
      return;
    }
    if (
      name === "returnPhoneNumber" &&
      value.replace(/\D/g, "").length > 10 &&
      e.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      return;
    }
    if (name === "returnAddressZip" && isInputNotANumber(e.nativeEvent.data)) {
      return;
    }
    if (
      name === "returnAddressZip" &&
      value.replace(/\D/g, "").length > 5 &&
      e.nativeEvent.inputType !== "deleteContentBackward"
    ) {
      return;
    }
    
    setFormData((data: any) => ({
      ...data,
      [name]: value,
    }));
  };

  const getSubscriptionID = (
    subscription: "lite" | "pro" | "platinum"
  ) => {
    if (subscription === "lite") {
      return 1;
    }
    if (subscription === "pro") {
      return 2;
    }
    if (subscription === "platinum") {
      return 3;
    }
    return 0;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let errors = validate(formData);
    if (
      errors.subscription ||
      errors.keapID ||
      errors.firstName ||
      errors.lastName ||
      errors.email ||
      errors.companyName ||
      errors.companyWebsite ||
      errors.returnAddressLine1 ||
      errors.returnAddressLine2 ||
      errors.returnAddressCity ||
      errors.returnAddressState ||
      errors.returnAddressZip ||
      errors.returnPhoneNumber
    ) {
      return setFormErrors(validate(formData));
    } else {
      let cardToken = null;
      try {
        setSubmitting(true);
        const {
          appID,
          email,
          keapID,
          subscription,
          firstName,
          lastName,
          companyName,
          companyWebsite,
          returnAddressLine1,
          returnAddressLine2,
          returnAddressCity,
          returnAddressState,
          returnAddressZip,
          returnPhoneNumber,
          isAnnualSubscription
        } = formData;
        const nameOnCard = `${formData.firstName} ${formData.lastName}`;
        cardToken = await createCardToken(nameOnCard);
        await adminApi.onboardTeam({
          appID,
          email,
          subscriptionID: getSubscriptionID(subscription),
          subscription,
          firstName,
          lastName,
          companyName,
          companyWebsite,
          returnAddressLine1,
          returnAddressLine2,
          returnAddressCity,
          returnAddressState,
          returnAddressZip,
          returnPhoneNumber,
          nameOnCard,
          keapID: parseInt(keapID),
          billingAddressLine1: returnAddressLine1,
          billingAddressLine2: returnAddressLine2,
          billingAddressCity: returnAddressCity,
          billingAddressState: returnAddressState,
          billingAddressZip: returnAddressZip,
          isLeader: true,
          password: generatePassword(),
          paymentMethod: cardToken,
          adminOnboard: true,
          isAnnualSubscription: formData.isAnnualSubscription === "monthly" ? false : true,
        });
        setShowModal(false);
        setAlert({
          display: true,
          message: `Successfully onboarded ${formData.companyName}`,
          type: "success",
        });
      } catch (e: any) {
        console.warn(e.message);
        setShowModal(false);
        setAlert({
          display: true,
          message: e.message,
          type: "error",
        });
      } finally {
        setSubmitting(false);
        callBack && callBack();
      }
    }
  };

  const validate: any = (data: any) => {
    const errors: {
      subscription?: string;
      keapID?: string;
      firstName?: string;
      lastName?: string;
      email?: string;
      companyName?: string;
      companyWebsite?: string;
      returnAddressLine1?: string;
      returnAddressLine2?: string;
      returnAddressCity?: string;
      returnAddressState?: string;
      returnAddressZip?: string;
      returnPhoneNumber?: string;
      nameOnCard?: string;
      billingAddressLine1?: string;
      billingAddressLine2?: string;
      billingAddressCity?: string;
      billingAddressState?: string;
      billingAddressZip?: string;
    } = {};
    if (!data.subscription) {
      errors.subscription = "A subscription is required";
    }
    if (!data.keapID) {
      errors.keapID = "A Keap ID is required";
    }
    if (!data.email) {
      errors.email = "An email address is required";
    }
    if (!data.companyName) {
      errors.companyName = "Company Name is required";
    }
    // if (data.companyWebsite && !isValidURL(data.companyWebsite)) {
    //   errors.companyWebsite = "Please enter a correct website URL";
    // }
    if (!data.returnAddressLine1) {
      errors.returnAddressLine1 = "Address is required";
    }
    if (!data.returnAddressCity) {
      errors.returnAddressCity = "City is required";
    }
    if (!data.returnAddressState) {
      errors.returnAddressState = "State is required";
    }
    if (!data.returnAddressZip) {
      errors.returnAddressZip = "Zipcode is required";
    }
    if (!data.returnPhoneNumber) {
      errors.returnPhoneNumber = "Phone Number is required";
    } else if (data.returnPhoneNumber.replace(/\D/g, "").length !== 10) {
      errors.returnPhoneNumber = "Phone Number must be 10 digits";
    }
    return errors;
  };

  const parsePhone = (phone: string) => {
    if (!phone) return "";
    if ((phone || "").length === 10) {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    } else {
      return phone.replace(/\D/g, "");
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-1/2">
        <SelectInput
            name="appID"
            label="Application"
            placeholder="Application"
            value={formData.appID.toString()}
            onChange={handleChange}
            options={[
              {
                label: "SendFuse",
                value: "1",
              },
              // {
              //   label: "REISnap",
              //   value: "2",
              // },
            ]}
          />
        </div>
        <div className="w-1/2">
          <SelectInput
            name="subscription"
            label="Subscription"
            placeholder="Subscription"
            value={formData.subscription}
            onChange={handleChange}
            error={formErrors.subscription}
            options={[
              {
                label: "Platinum",
                value: "platinum",
              },
              {
                label: "Pro",
                value: "pro",
              },
              {
                label: "Lite",
                value: "lite",
              },
            ]}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2 mt-3">
        <div className="w-1/2">
          <TextInput
            name="keapID"
            label="Keap ID"
            placeholder="KeapID"
            value={formData.keapID}
            onChange={handleChange}
            error={formErrors.keapID}
            labelProps={{ className: "dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
          />
        </div>
        <div className="w-1/2">
          <SelectInput
            name="isAnnualSubscription"
            label="Subscription Type"
            placeholder="Subscription"
            value={formData.isAnnualSubscription}
            onChange={handleChange}
            error={formErrors.subscription}
            options={[
              {
                label: "Monthly",
                value: "false",
              },
              {
                label: "Annual",
                value: "true",
              },
            ]}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-1/2">
          <TextInput
            name="email"
            label="Email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.email}
          />
        </div>
        <div className="w-1/2">
          <TextInput
            name="returnPhoneNumber"
            label="Phone Number"
            placeholder="Phone Number"
            value={parsePhone(formData.returnPhoneNumber)}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.returnPhoneNumber}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-1/2">
          <TextInput
            name="firstName"
            label="First Name"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.firstName}
          />
        </div>
        <div className="w-1/2">
          <TextInput
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.lastName}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-1/2">
          <TextInput
            name="companyName"
            label="Company Name"
            placeholder="Company Name"
            value={formData.companyName}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.companyName}
          />
        </div>
        <div className="w-1/2">
          <TextInput
            name="companyWebsite"
            label="Company Website"
            placeholder="Company Website"
            value={formData.companyWebsite}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.companyWebsite}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-1/2">
          <TextInput
            name="returnAddressLine1"
            label="Address Line 1"
            placeholder="Address Line 2"
            value={formData.returnAddressLine1}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.returnAddressLine1}
          />
        </div>
        <div className="w-1/2">
          <TextInput
            name="returnAddressLine2"
            label="Address Line 2"
            placeholder="Address Line 2"
            value={formData.returnAddressLine2}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.returnAddressLine2}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-between gap-2">
        <div className="w-5/12">
          <TextInput
            name="returnAddressCity"
            label="City"
            placeholder="City"
            value={formData.returnAddressCity}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.returnAddressCity}
          />
        </div>
        <div className="w-2/12">
          <SelectInput
            name="returnAddressState"
            label="State"
            value={formData.returnAddressState}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.returnAddressState}
            options={States}
          />
        </div>
        <div className="w-5/12">
          <TextInput
            name="returnAddressZip"
            label="Zipcode"
            placeholder="Zipcode"
            value={formData.returnAddressZip}
            onChange={handleChange}
            labelProps={{ className: "mt-4 dark:text-text-light" }}
            inputProps={{ className: "dark:bg-back-dark" }}
            error={formErrors.returnAddressZip}
          />
        </div>
      </div>
      <div className="relative my-4 flex w-full">
        <StripeForm />
      </div>
      <div className="mt-4 flex items-center justify-center gap-2">
        <Button
          className="btn-ghost btn w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="btn w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
          onClick={handleSubmit}
          loading={submitting}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default withStripeProvider(NewTeamOnboardingModal);
