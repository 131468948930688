import React, { useState, useEffect } from "react";
import OfferQuantity from "src/components/offers/OfferQuantity";
import OfferVolume from "src/components/offers/OfferVolume";
import useOfferContext from "src/hooks/useOffers";
import { motion } from "framer-motion";
import OfferStatusChart from "src/components/offers/OfferStatusChart";
import OfferAverages from "src/components/offers/OfferAverages";
import { Offer } from "src/interfaces/offer";
import OffersSent from "src/components/offers/OffersSent";
import TopOfferTeams from "src/components/offers/TopOfferTeams";
import { Team } from "src/interfaces/team";
import offerApi from "src/api/offers";
import { useParams } from "react-router-dom";
interface TitleBoxProps {
  title: string;
  isActive: boolean;
}

const TitleBox = ({ title, isActive }: TitleBoxProps) => {
  return (
    <motion.div
      className={
        isActive
          ? "rounded-xl border-4 border-secondary bg-secondary px-4 text-white"
          : "rounded-xl border-4 border-back-light bg-back-light px-4 text-black"
      }
      initial={{ opacity: 0, y: -50 }}
      animate={isActive ? { opacity: 1, y: 0 } : { opacity: 0.25, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {title}
    </motion.div>
  );
};

const OfferPortal = () => {
  const { currentIndex: currentIndexParam } = useParams();
  const parsedIndex = parseInt(currentIndexParam || "0", 10);

  const { offers, fetchOffers } = useOfferContext();
  const [teamsQuantity, setTeamsQuantity] = useState<Team[]>([]);
  const [teamsVolume, setTeamsVolume] = useState<Team[]>([]);

  const timeContexts = ["All", "Today", "Week", "Month", "Quarter", "Year"];

  const [currentContext, setCurrentContext] = useState<string>(timeContexts[0]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [filteredOffers, setFilteredOffers] = useState<Offer[]>([]);

  const filterOffersByTimeContext = (offers: Offer[], timeContext: string) => {
    const now = new Date();
    switch (timeContext) {
      case "Today":
        now.setHours(0, 0, 0, 0);
        return offers.filter((offer: Offer) => new Date(offer.createdAt) > now);
      case "Week":
        const weekStart = new Date();
        weekStart.setDate(now.getDate() - now.getDay());
        return offers.filter(
          (offer: Offer) => new Date(offer.createdAt) > weekStart
        );
      case "Month":
        const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
        return offers.filter(
          (offer: Offer) => new Date(offer.createdAt) > monthStart
        );
      case "Quarter":
        const quarterStart = new Date(
          now.getFullYear(),
          Math.floor(now.getMonth() / 3) * 3,
          1
        );
        return offers.filter(
          (offer: Offer) => new Date(offer.createdAt) > quarterStart
        );
      case "Year":
        const yearStart = new Date(now.getFullYear(), 0, 1);
        return offers.filter(
          (offer: Offer) => new Date(offer.createdAt) > yearStart
        );
      default:
        return offers;
    }
  };

  function abbreviateNumber(value: number): string {
    let newValue = value;
    if (value >= 1000000) {
      newValue = value / 1000000;
      return newValue.toFixed(1) + "M";
    } else if (value >= 1000) {
      newValue = value / 1000;
      return newValue.toFixed(1) + "K";
    }
    return value.toString(); // If value < 1000, return the original number
  }

  useEffect(() => {
    if (!Number.isNaN(parsedIndex)) {
      setCurrentIndex(parsedIndex);
      setCurrentContext(timeContexts[parsedIndex]);
    }
  }, [parsedIndex, timeContexts]);

  useEffect(() => {
    fetchOffers();
    const fetchInterval = setInterval(fetchOffers, 60000);
    return () => clearInterval(fetchInterval);
  }, [fetchOffers]);

  useEffect(() => {
    setFilteredOffers(filterOffersByTimeContext(offers, currentContext));
  }, [offers, currentContext]);

  useEffect(() => {
    offerApi.getTopTeamsQuantity(currentContext).then(setTeamsQuantity);
    offerApi.getTopTeamsVolume(currentContext).then(setTeamsVolume);
  }, [currentContext]);

  return (
    <div className="h-screen w-full bg-back-light px-4 py-2">
      <div className="h-5/12 bg-back-light">
        <div className="relative mb-2 flex w-full items-center justify-between text-[50px] font-bold text-black">
          {timeContexts.map((timeContext) => (
            <TitleBox
              key={timeContext}
              title={timeContext}
              isActive={currentContext === timeContext}
            />
          ))}
        </div>
        <div className="flex h-full flex-row gap-2">
          <div className="flex h-full gap-2">
            <div className="flex w-96 columns-1 flex-col gap-2">
              <OfferVolume offers={filteredOffers} />
              <OfferQuantity offers={filteredOffers} />
            </div>
            <div className=" w-96">
              <OfferStatusChart offers={filteredOffers} />
            </div>
            <div className=" w-96">
              <OfferAverages offers={filteredOffers} />
            </div>
            <div className=" w-96">
              <OffersSent offers={filteredOffers} />
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <div className="mt-2 w-96">
            <TopOfferTeams teams={teamsQuantity} subTable="Quantity" />
          </div>
          <div className="mt-2 w-96">
            <TopOfferTeams
              teams={teamsVolume}
              subTable="Volume"
              sanitizeFunction={abbreviateNumber}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferPortal;
