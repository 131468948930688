import {
  faArrowTrendDown,
  faArrowTrendUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { statisticsApi } from "src/api";
import { addCommas } from "src/helpers/parseStrings";

interface StatDescProps {
  currentNum: number;
  lastMonthNum: number;
}

const StatDesc = ({ currentNum, lastMonthNum }: StatDescProps) => {
  const isGrowth = currentNum > lastMonthNum;

  const percentageDifference = isGrowth
    ? ((currentNum - lastMonthNum) / lastMonthNum) * 100
    : ((lastMonthNum - currentNum) / currentNum) * 100;

  return (
    <div className="stat-desc flex gap-1.5">
      <FontAwesomeIcon
        className={isGrowth ? "text-sm text-green-700 dark:text-success" : "text-sm text-error"}
        icon={isGrowth ? faArrowTrendUp : faArrowTrendDown}
      />
      <span
        className={isGrowth ? "text-xs text-green-700 dark:text-success" : "text-xs text-error"}
      >
        $ {addCommas(currentNum - lastMonthNum)}
      </span>
      <span
        className={isGrowth ? "text-xs text-green-700 dark:text-success" : "text-xs text-error"}
      >
        (%{parseFloat(percentageDifference.toFixed(2))})
      </span>
      <span className="text-zinc-500 dark:text-zinc-400">this month</span>
    </div>
  );
};

const AverageOfferAmount = () => {
  const [averageOffers, setAverageOffers] = useState<any>({
    allCashAverage: 0,
    sellerCarryAverage: 0,
    sellerFinanceAverage: 0,
    allOffersAverage: 0,
  });

  const [averageOffersLess30, setAverageOffersLess30] = useState<any>({
    allCashAverage: 0,
    sellerCarryAverage: 0,
    sellerFinanceAverage: 0,
    allOffersAverage: 0,
  });

  const fetchAverageOffers = async () => {
    const response = await statisticsApi.getAverageOffer();
    const responseLess30 = await statisticsApi.getAverageOfferLess30();
    const { avg_offer1, avg_offer2, avg_offer3, avg_all_offers } = response;
    const {
      avg_offer1_less30,
      avg_offer2_less30,
      avg_offer3_less30,
      avg_all_offers_less30,
    } = responseLess30;
    setAverageOffers({
      allOffersAverage: avg_all_offers,
      allCashAverage: avg_offer1,
      sellerCarryAverage: avg_offer2,
      sellerFinanceAverage: avg_offer3,
    });

    setAverageOffersLess30({
      allOffersAverage: avg_all_offers_less30,
      allCashAverage: avg_offer1_less30,
      sellerCarryAverage: avg_offer2_less30,
      sellerFinanceAverage: avg_offer3_less30,
    });
  };

  useEffect(() => {
    fetchAverageOffers();
  }, []);

  return (
    <>
      <div className="stats stats-vertical w-full bg-card-light shadow dark:bg-card-dark">
        <div className="stat py-1.5">
          <div className="stat-title text-zinc-600 dark:text-gray-400">
            Avg. All Offers
          </div>
          <div className="stat-value text-zinc-500 dark:text-slate-300">
            $ {addCommas(Math.round((Number(averageOffers.allCashAverage) + Number(averageOffers.sellerCarryAverage) + Number(averageOffers.sellerFinanceAverage)) / 3))}
          </div>
          <StatDesc
            currentNum={averageOffers.allOffersAverage}
            lastMonthNum={averageOffersLess30.allOffersAverage}
          />
        </div>

        <div className="stat py-1.5">
          <div className="stat-title text-zinc-600 dark:text-gray-400">
            Avg. All Cash
          </div>
          <div className="stat-value text-zinc-500 dark:text-slate-300">
            $ {addCommas(averageOffers.allCashAverage)}
          </div>
          <StatDesc
            currentNum={averageOffers.allCashAverage}
            lastMonthNum={averageOffersLess30.allCashAverage}
          />
        </div>

        <div className="stat py-1.5">
          <div className="stat-title text-zinc-600 dark:text-gray-400">
            Avg. Seller Carry
          </div>
          <div className="stat-value text-zinc-500 dark:text-slate-300">
            $ {addCommas(averageOffers.sellerCarryAverage)}
          </div>
          <StatDesc
            currentNum={averageOffers.sellerCarryAverage}
            lastMonthNum={averageOffersLess30.sellerCarryAverage}
          />
        </div>

        <div className="stat py-1.5">
          <div className="stat-title text-zinc-600 dark:text-gray-400">
            Avg. Seller Finance
          </div>
          <div className="stat-value text-zinc-500 dark:text-slate-300">
            $ {addCommas(averageOffers.sellerFinanceAverage)}
          </div>
          <StatDesc
            currentNum={averageOffers.sellerFinanceAverage}
            lastMonthNum={averageOffersLess30.sellerFinanceAverage}
          />
        </div>
      </div>
    </>
  );
};

export default AverageOfferAmount;
