import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import { ChartData } from "chart.js";

class StatisticsApi extends ApiTokenSupport {

  async getActionStats(
    period: string,
    interval: number
  ): Promise<ChartData<"bar">> {
    const resp = await axios.post<ChartData<"bar">>(
      `/api/adminstats/actions-stats`,
      { period, interval },
      this.withAuthorization()
    );
    return resp.data;
  }

  async countSubscriptions (): Promise<any> {
    const resp = await axios.get<any>(
      `/api/adminstats/count-subscriptions`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getMRRByType (): Promise<any> {
    const resp = await axios.get<any>(
      `/api/adminstats/get-mrr-by-type`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getAverageOffer (): Promise<any> {
    const resp = await axios.get<any>(
      `/api/adminstats/average-offer`,
      this.withAuthorization()
    );
    return resp.data[0]
  }

  async getAverageOfferLess30 (): Promise<any> {
    const resp = await axios.get<any>(
      `/api/adminstats/average-offer-less-30`,
      this.withAuthorization()
    );
    return resp.data[0]
  }

  async getChurnStats(
    interval: number,
  ): Promise<any> {
    const resp = await axios.post<any>(
      `/api/adminstats/churn-stats`,
      { interval },
      this.withAuthorization()
    );
    return resp.data;
  }

  async syncStripe(): Promise<boolean> {
    const resp = await axios.get<boolean>(
      `/api/adminstats/sync-stripe`,
      this.withAuthorization()
    );
    return resp.data
  }
}

export default new StatisticsApi();
