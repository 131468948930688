import React from "react";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import useAlert from "src/hooks/useAlert";
import useTheme from "src/hooks/useTheme";
import axios from "axios";

const backendURL = process.env.REACT_APP_BACKEND_URL;

interface CacheButton {
  title: string;
  link: string;
}

const cacheButtons: CacheButton[] = [
  {
    title: "Team",
    link: `${backendURL}/api/team/clear-cache`,
  },
  {
    title: "User",
    link: `${backendURL}/api/user/clear-cache`,
  },
  {
    title: "Team Settings",
    link: `${backendURL}/api/teamsettings/clear-cache`,
  },
  {
    title: "Property",
    link: `${backendURL}/api/property/clear-cache`,
  },
  {
    title: "Stripe Account",
    link: `${backendURL}/api/stripeaccount/clear-cache`,
  },
  {
    title: "Mail",
    link: `${backendURL}/api/mailing/clear-cache`,
  },
  {
    title: "Notification",
    link: `${backendURL}/api/notification/clear-cache`,
  },
  {
    title: "Campaign",
    link: `${backendURL}/api/campaign/clear-cache`,
  },
  {
    title: "Token",
    link: `${backendURL}/api/token/clear-cache`,
  },
  {
    title: "Calls/Emails",
    link: `${backendURL}/api/callsemails/clear-cache`,
  },
  {
    title: "Loan",
    link: `${backendURL}/api/loan/clear-cache`,
  },
  {
    title: "Property Photos",
    link: `${backendURL}/api/property-photos/clear-cache`,
  },
  {
    title: "Transaction",
    link: `${backendURL}/api/transaction/clear-cache`,
  },
  {
    title: "Admin",
    link: `${backendURL}/api/admin/clear-cache`,
  },
  {
    title: "Property Update",
    link: `${backendURL}/api/property-update/clear-cache`,
  },
  {
    title: "Sequence",
    link: `${backendURL}/api/sequence/clear-cache`,
  },
  {
    title: "Postcards",
    link: `${backendURL}/api/template-postcard/clear-cache`,
  },
  {
    title: "Letters",
    link: `${backendURL}/api/template-letter/clear-cache`,
  },
  {
    title: "Debt Stack",
    link: `${backendURL}/api/title/clear-cache`,
  },
  {
    title: "Offer",
    link: `${backendURL}/api/offer/clear-cache`,
  },
];

interface CacheCardProps {
  props?: any;
}

const CacheCard = ({ props }: CacheCardProps) => {
  const { tailwindTheme } = useTheme();

  const { setAlert } = useAlert();

  const { handleError } = useErrorHandler();

  const colors = [
    tailwindTheme.theme1,
    tailwindTheme.theme2,
    tailwindTheme.theme3,
    tailwindTheme.theme4,
    tailwindTheme.theme5,
  ];

  const assignColor = (index: number) => {
    return colors[index % colors.length];
  };

  const handleClick = async (src: string, title: string) => {
    try {
      const resp = await axios.post(src);
      console.log(resp);
      if (resp) {
        setAlert({
          display: true,
          type: "success",
          message: `Successfully cleared the cache for the ${title} table`,
        });
      }
    } catch (e) {
      return handleError(e);
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="card bg-card-light px-4 py-4 dark:bg-card-dark">
          <div className="card-title text-black dark:text-white">
            <h1>Clear Cache</h1>
          </div>
          <div className="card-body flex w-full flex-row flex-wrap items-center gap-2 px-0">
            {cacheButtons.map((button: CacheButton, index: number) => (
              <button
                key={index}
                style={{
                  background: assignColor(index),
                }}
                className="btn btn-sm text-white dark:text-black"
                onClick={() => handleClick(button.link, button.title)}
              >
                {button.title}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CacheCard;
