import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import usePlacesAutocomplete from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import SendFuse from "../../../api/sendfuse";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import useAlert  from "src/hooks/useAlert";

const SearchBarMobile = () => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
    },
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e: any): void => {
    setValue(e.target.value);
  };

  const handleSelect = (e: any): void => {
    setValue(e.target.innerHTML, false);
    clearSuggestions();
  };

  

  const { alert, setAlert } = useAlert();

  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let result = await SendFuse.researchProperty(value);
    if (result.success) {

      navigate(`/properties/${result.data.id}`);
      setAlert({
        display: true,
        message: "New Property Added",
        type: "success",
      });
    } else {
      setAlert({
        display: true,
        message: "Unable to find research this propety",
        type: "error",
      });
    }
  };

  //handle clicking outside the input box to still focus on search bar
  const searchInput = useRef<any>(null);
  const handleClick = () => {
    if (null !== searchInput.current) {
      searchInput.current.focus();
    }
  };

  return (
    <>
      <div ref={ref} className="bg-back-light dark:bg-back-dark">
        <div
          onClick={handleClick}
          className="fixed flex items-center justify-center w-full h-24 bg-back-light dark:bg-back-dark left-0 top-20 z-20"
        >
          <div className="flex items-center w-full mx-4 shadow-md border-solid bg-card-blend-light dark:bg-card-dark rounded-lg ">
            <div className="">
              <FontAwesomeIcon
                className="text-purple pl-4 text-xl md:text-3xl text-center"
                icon={faMagnifyingGlass}
              />
            </div>
            <div className="min-w-screen">
              <form onSubmit={handleSubmit}>
                <input
                  id="searchproperties"
                  ref={searchInput}
                  type="text"
                  name="search"
                  placeholder="Search properties"
                  className={
                    "ml-2 placeholder:italic placeholder:text-lg bg-card-blend-light dark:bg-card-dark font-medium h-16 focus:outline-none border-none text-slate-dark dark:text-white text-xs md:text-3xl mx-12 w-64"
                  }
                  value={value}
                  onChange={handleInput}
                  disabled={!ready}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBarMobile;
