import { useEffect, useState } from "react";
import { timeAgo } from "src/helpers/parseEpochs";
import AdminApi from "src/api/admin";
import SubscriptionBadge from "../badges/SubscriptionBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDove,
  faLink,
  faMagnifyingGlass,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import useModal from "src/hooks/useModal";
import ChangeSubscriptionModal from "../modals/ChangeSubscriptionModal";
import CoinBalance from "../buttons/CoinBalance";
import { TeamPageData } from "src/interfaces/TeamPageData";
import useAlert from "src/hooks/useAlert";
import adminApi from "src/api/admin";
import FailedPaymentModal from "../modals/FailedPaymentModal";
import SubscriptionModal from "../modals/SubscriptionModal";
import CircularActionButton from "../buttons/CircularActionButton";
import EditTeamModal from "../modals/EditTeamModal";
import AppButton from "../buttons/AppButton";

interface TeamInfoProps {
  team: TeamPageData;
  fetchTeam: () => void;
  handleActive: (title: string) => void;
  fetchNotes: () => void;
}

const TeamInfo = ({
  team,
  fetchTeam,
  handleActive,
  fetchNotes,
}: TeamInfoProps) => {
  const {
    id,
    isStaff,
    hasFailedPayments,
    hasFailedPaymentDays,
    createdAt,
    companyName,
    website,
  } = team.team;
  const { hasMigrated } = team.user;

  const [transactions, setTransactions] = useState([]);
  const [users, setUsers] = useState([]);

  const { openModalWith, setShowModal } = useModal();

  const { setAlert } = useAlert();

  const onResolveSubmit = async () => {
    try {
      const success = await adminApi.resolveFailedPayment(id);
      if (success) {
        setAlert({
          display: true,
          type: "success",
          message: `${companyName}'s failed payment has been resolved`,
        });
      }
      setShowModal(false);
    } catch (e) {
      console.log(e);
      setShowModal(false);
      setAlert({
        display: true,
        type: "error",
        message: `There was an error resolving ${companyName}'s failed payment`,
      });
    } finally {
      fetchTeam();
    }
  };

  const fetchUsers = async () => {
    if (id) {
      const response = await AdminApi.getTeamUsers(id);
      setUsers(response);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [id]);

  const fetchTransactions = async () => {
    if (id) {
      const response = await AdminApi.getTeamTransactions(id);
      setTransactions(response);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [id]);

  const countTransactions = () => {
    let count = 0;
    transactions.forEach((transaction: any) => {
      if (transaction.type === "debit") {
        count++;
      }
    });
    return count;
  };

  const handleSubscriptionClick = () => {
    openModalWith({
      title: "Change Subscription",
      body: <SubscriptionModal team={team} fetchTeam={fetchTeam} />,
      hideButtons: true,
    });
  };

  const handleFailedPaymentsClick = () => {
    openModalWith({
      title: "Failed Payment",
      body: <FailedPaymentModal team={team} />,
      submitLabel: "Resolve",
      onSubmit: onResolveSubmit,
    });
  };

  function ensureHttpsUrl(url: string): string {
    if (!url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  }

  const handleEditTeam = () => {
    openModalWith({
      title: "Edit Team",
      body: <EditTeamModal team={team} fetchTeam={fetchTeam} />,
      hideButtons: true,
    });
  };

  return (
    <>
      <div className="card w-full rounded-lg bg-white px-6 py-3 shadow-lg dark:bg-card-dark">
        <div className="mb-3 flex items-center justify-between">
          <div className="flex flex-row gap-2">
            <h1 className="text-[25px] font-extrabold text-secondary">
              <p>{companyName}</p>
            </h1>
            <span
              className={
                "mb-1 flex items-end text-sm font-semibold text-zinc-400"
              }
            >
              #{id}
            </span>
          </div>
          <div>
            <CircularActionButton
              icon={faPenToSquare}
              onClick={handleEditTeam}
            />
          </div>
        </div>
        <div className="mb-2 flex items-center justify-between gap-1">
          <AppButton app={team.app} />
          <div className="flex items-center justify-between">
            <div className="btn-group ">
              {website && website.length ? (
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={website ? `${ensureHttpsUrl(website)}` : ""}
                  className="btn-outline btn-sm btn border-zinc-500 text-text-dark hover:border-text-dark hover:bg-back-light dark:text-text-light hover:dark:border-text-light dark:hover:bg-back-dark"
                >
                  <FontAwesomeIcon icon={faLink} className="text-base" />
                </a>
              ) : (
                <></>
              )}
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.google.com/search?q=${companyName}`}
                className="btn-outline btn-sm btn border-zinc-500 text-text-dark hover:border-text-dark hover:bg-back-light dark:text-text-light hover:dark:border-text-light dark:hover:bg-back-dark"
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="text-base"
                />
              </a>
            </div>
          </div>
          <div>
            {hasMigrated && (
              <div
                className="tooltip tooltip-bottom p-1"
                data-tip={"This team migrated from V1"}
              >
                <FontAwesomeIcon icon={faDove} />
              </div>
            )}
            <SubscriptionBadge
              team={team}
              error={hasFailedPayments}
              days={hasFailedPaymentDays}
              onClick={
                isStaff
                  ? () => {}
                  : hasFailedPayments
                  ? handleFailedPaymentsClick
                  : handleSubscriptionClick
              }
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button className="btn-sm btn gap-2 border-zinc-500 bg-card-light text-text-dark drop-shadow-lg hover:border-zinc-500 hover:bg-card-light dark:bg-card-dark dark:text-text-light dark:hover:bg-card-dark">
            Age
            <div className="badge badge-primary text-text-light dark:text-text-dark">
              {timeAgo(createdAt)}
            </div>
          </button>
          <button className="btn-sm btn gap-2 border-zinc-500 bg-card-light text-text-dark drop-shadow-lg hover:border-zinc-500 hover:bg-card-light dark:bg-card-dark dark:text-text-light dark:hover:bg-card-dark">
            Actions
            <div className="badge badge-accent text-text-light dark:text-text-dark">
              {countTransactions()}
            </div>
          </button>
          <button
            className="btn-sm btn gap-2 border-zinc-500 bg-card-light text-text-dark drop-shadow-lg hover:border-zinc-500   hover:bg-card-light dark:bg-card-dark dark:text-text-light dark:hover:bg-card-dark"
            onClick={() => handleActive("Team")}
          >
            Team
            <div className="badge badge-secondary  text-text-light dark:text-text-dark">
              {users.length}
            </div>
          </button>
          <CoinBalance
            team={team}
            fetchTeam={fetchTeam}
            fetchNotes={fetchNotes}
          />
        </div>
      </div>
    </>
  );
};

export default TeamInfo;
