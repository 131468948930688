import React, { useState } from "react";
import useModal from "src/hooks/useModal";
import Button from "../buttons/Button";
import FloatingInput from "../fields/FloatingInputText";
import SelectInput from "../fields/SelectInput";
import { regexEmail } from "src/helpers/constants";
import AdminApi from "src/api/admin";
import useAlert from "src/hooks/useAlert";

interface NewDemoTeamModalProps {
  fetchDemoTokens: () => void;
}

const NewDemoModeTeamModal = ({ fetchDemoTokens }: NewDemoTeamModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const initialFormData: {
    email: string;
    amountOfTime: string;
  } = {
    email: "",
    amountOfTime: "5",
  };

  const initialFormErrors = {
    email: "",
  };

  interface FormErrors {
    email: string;
  }

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const validate: any = (data: any) => {
    const errors: {
      email?: string;
    } = {};
    if (!data.email) {
      errors.email = "Email is required";
    } else if (!regexEmail.test(data.email)) {
      errors.email = "Email is not a valid format";
    }
    return errors;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let errors = validate(formData);
    if (errors.email) {
      return setFormErrors(errors);
    } else {
      setShowModal(false);
      const { email, amountOfTime } = formData;
      try {
        let result = await AdminApi.addDemoTeam({
          email: email.toLowerCase(),
          amountOfTime:
            amountOfTime === "Infinity"
              ? parseFloat(amountOfTime)
              : parseInt(amountOfTime),
        });
        if (result.status) {
          setAlert({
            display: true,
            message: `New Demo Mode Token created for ${email}`,
            type: "success",
          });
          setFormData(initialFormData);
        }
      } catch (e: any) {
        setAlert({
          display: true,
          message:
            e.message ||
            "An error occurred trying to create a new Demo Mode Token",
          type: "error",
        });
      } finally {
        fetchDemoTokens();
      }
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="mx-4 mb-6 lg:mx-32">
        <div className="mb-2">
          <FloatingInput
            name={"email"}
            label={"Email"}
            type={"text"}
            onChange={handleChange}
            value={formData.email}
            error={formErrors.email}
          />
        </div>
        <div className="mb-2">
          <div>
            <SelectInput
              label={"Duration"}
              value={formData.amountOfTime.toString()}
              name={"amountOfTime"}
              labelProps={{
                className: "font-medium text-left ml-3",
              }}
              inputProps={{
                className:
                  "border border-secondary focus:ring-none rounded-[9px] h-[55px] ring-0",
              }}
              options={[
                { label: "1 Day", value: "1" },
                { label: "2 Day", value: "2" },
                { label: "3 Days", value: "3" },
                { label: "4 Days", value: "4" },
                { label: "5 Days", value: "5" },
                { label: "6 Days", value: "6" },
                { label: "7 Days", value: "7" },
                { label: "Indefinitely", value: "5475" },
              ]}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-2">
        <Button
          className="btn-ghost btn w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="btn w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default NewDemoModeTeamModal;
