import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  getLastWeek,
  getLastSixWeeks,
  getLastTwelveWeeks,
  getLastSixMonths,
  getLastTwelveMonths,
  countOccurances,
  countOccurancesLastWeek,
  countOccurancesMonthly,
} from "src/helpers/chartHelpers";
import useTheme from "src/hooks/useTheme";
import useViewport from "src/hooks/useViewport";
import Property from "src/interfaces/property";
import AdminApi from "src/api/admin";
import { PhoneCall } from "src/interfaces/phoneCall";
import { Sequence } from "src/interfaces/sequence";
import { DebtStack } from "src/interfaces/debtStack";
import { Offer } from "src/interfaces/offer";
import { TeamPageData } from "src/interfaces/TeamPageData";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ActionStatisticsProps {
  team: TeamPageData;
}

const ActionStatistics = ({ team }: ActionStatisticsProps) => {
  const [properties, setProperties] = useState<Property[]>([]);
  const [phoneCalls, setPhoneCalls] = useState<PhoneCall[]>([]);
  const [sequences, setSequences] = useState<Sequence[]>([]);
  const [debtStacks, setDebtStacks] = useState<DebtStack[]>([]);
  const [offers, setOffers] = useState<Offer[]>([]);

  const { id } = team.team;

  const fetchProperties = async () => {
    if (team.team.id) {
      const response = await AdminApi.getProperties(id);
      setProperties(response);
    }
  };
  const fetchPhoneCalls = async () => {
    if (id) {
      const response = await AdminApi.getPhoneCalls(id);
      setPhoneCalls(response);
    }
  };
  const fetchSequences = async () => {
    if (id) {
      const response = await AdminApi.getSequences(id);
      setSequences(response);
    }
  };
  const fetchDebtStacks = async () => {
    if (id) {
      const response = await AdminApi.getDebtStacks(id);
      setDebtStacks(response);
    }
  };
  const fetchOffers = async () => {
    if (id) {
      const response = await AdminApi.getOffers(id);
      setOffers(response);
    }
  };

  useEffect(() => {
    fetchProperties();
    fetchPhoneCalls();
    fetchSequences();
    fetchDebtStacks();
    fetchOffers();
  }, [team]);

  const { mode, tailwindTheme } = useTheme();

  const { width } = useViewport();

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          color: mode === "dark" ? "#FFFFFF" : "#000000",
          font: {
            size: width < 640 ? 10 : width < 1024 ? 12 : 14,
            family: "sans-serif",
          },
          boxWidth: width < 640 ? 12 : 16,
          boxHeight: width < 640 ? 12 : 16,
        },
      },
      title: {
        display: false,
        text: "Action Statistics",
        color: mode === "light" ? "#7E7D85" : "#ABA9B4",
        padding: 0,
        font: {
          size: width < 640 ? 13 : 17,
          family: "sans-serif",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: width < 640 ? 8 : 12,
          },
          color: mode === "dark" ? "#FFFFFF" : "#000000",
        },
      },
      y: {
        ticks: {
          font: {
            size: width < 640 ? 8 : 12,
          },
          color: mode === "dark" ? "#FFFFFF" : "#000000",
        },
      },
    },
  };

  const initialLabels = getLastTwelveWeeks();
  const [labels, setLabels] = useState(initialLabels);

  const [researchCounts, setResearchCounts] = useState<number[]>(
    countOccurances(properties, 604800000, 12)
  );
  const [phoneCallCounts, setPhoneCallCounts] = useState<number[]>(
    countOccurances(phoneCalls, 604800000, 12)
  );
  const [titleCounts, setTitleCounts] = useState<number[]>(
    countOccurances(debtStacks, 604800000, 12)
  );
  const [sequenceCounts, setSequenceCounts] = useState<number[]>(
    countOccurances(sequences, 604800000, 12)
  );
  const [offerCounts, setOfferCounts] = useState<number[]>(
    countOccurances(offers, 604800000, 12)
  );

  const initialDataSetValue = [
    {
      label: "Researches",
      data: researchCounts,
      backgroundColor: tailwindTheme?.theme1,
      order: 1,
    },
    {
      label: "Calls/Emails",
      data: phoneCallCounts,
      backgroundColor: tailwindTheme?.theme4,
      order: 2,
    },
    {
      label: "Debt Stacks",
      data: titleCounts,
      backgroundColor: tailwindTheme?.theme5,
      order: 3,
    },
    {
      label: "Sequences",
      data: sequenceCounts,
      backgroundColor: tailwindTheme?.theme2,
      order: 4,
    },
    {
      label: "Offers",
      data: offerCounts,
      backgroundColor: tailwindTheme?.theme3,
      order: 5,
    },
  ];

  const [dataSets, setDataSets] = useState(initialDataSetValue);

  useEffect(() => {
    setResearchCounts(countOccurances(properties, 604800000, 12));
    setPhoneCallCounts(countOccurances(phoneCalls, 604800000, 12));
    setTitleCounts(countOccurances(debtStacks, 604800000, 12));
    setSequenceCounts(countOccurances(sequences, 604800000, 12));
    setOfferCounts(countOccurances(offers, 604800000, 12));
    setDataSets(initialDataSetValue);
  }, [properties, phoneCalls, debtStacks, sequences, offers]);

  const chartData = {
    labels,
    datasets: dataSets,
  };

  const [twelveMonths, setTwelveMonths] = useState(false);
  const [sixMonths, setSixMonths] = useState(false);
  const [twelveWeeks, setTwelveWeeks] = useState(true);
  const [sixWeeks, setSixWeeks] = useState(false);
  const [week, setWeek] = useState(false);

  const [stateToUpdate, setStateToUpdate] = useState("twelveWeeks");

  const handleTimeChange = (stateToUpdate: string) => {
    if (properties && phoneCalls && debtStacks && sequences && offers) {
      if (stateToUpdate === "twelveMonths") {
        setTwelveMonths(true);
        setSixMonths(false);
        setTwelveWeeks(false);
        setSixWeeks(false);
        setWeek(false);
        setLabels(getLastTwelveMonths());
        setResearchCounts(countOccurancesMonthly(properties, 12));
        setPhoneCallCounts(countOccurancesMonthly(phoneCalls, 12));
        setTitleCounts(countOccurancesMonthly(debtStacks, 12));
        setSequenceCounts(countOccurancesMonthly(sequences, 12));
        setOfferCounts(countOccurancesMonthly(offers, 12));
        setStateToUpdate("twelveMonths");
      } else if (stateToUpdate === "sixMonths") {
        setTwelveMonths(false);
        setSixMonths(true);
        setTwelveWeeks(false);
        setSixWeeks(false);
        setWeek(false);
        setLabels(getLastSixMonths());
        setResearchCounts(countOccurancesMonthly(properties, 6));
        setPhoneCallCounts(countOccurancesMonthly(phoneCalls, 6));
        setTitleCounts(countOccurancesMonthly(debtStacks, 6));
        setSequenceCounts(countOccurancesMonthly(sequences, 6));
        setOfferCounts(countOccurancesMonthly(offers, 6));
        setStateToUpdate("sixMonths");
      } else if (stateToUpdate === "twelveWeeks") {
        setTwelveMonths(false);
        setSixMonths(false);
        setTwelveWeeks(true);
        setSixWeeks(false);
        setWeek(false);
        setLabels(getLastTwelveWeeks());
        setResearchCounts(countOccurances(properties, 604800000, 12));
        setPhoneCallCounts(countOccurances(phoneCalls, 604800000, 12));
        setTitleCounts(countOccurances(debtStacks, 604800000, 12));
        setSequenceCounts(countOccurances(sequences, 604800000, 12));
        setOfferCounts(countOccurances(offers, 604800000, 12));
        setStateToUpdate("twelveWeeks");
      } else if (stateToUpdate === "sixWeeks") {
        setTwelveMonths(false);
        setSixMonths(false);
        setTwelveWeeks(false);
        setSixWeeks(true);
        setWeek(false);
        setLabels(getLastSixWeeks());
        setResearchCounts(countOccurances(properties, 604800000, 6));
        setPhoneCallCounts(countOccurances(phoneCalls, 604800000, 6));
        setTitleCounts(countOccurances(debtStacks, 604800000, 6));
        setSequenceCounts(countOccurances(sequences, 604800000, 6));
        setOfferCounts(countOccurances(offers, 604800000, 6));
        setStateToUpdate("sixWeeks");
      } else if (stateToUpdate === "week") {
        setTwelveMonths(false);
        setSixMonths(false);
        setTwelveWeeks(false);
        setSixWeeks(false);
        setWeek(true);
        setLabels(getLastWeek());
        setResearchCounts(countOccurancesLastWeek(properties));
        setPhoneCallCounts(countOccurancesLastWeek(phoneCalls));
        setTitleCounts(countOccurancesLastWeek(debtStacks));
        setSequenceCounts(countOccurancesLastWeek(sequences));
        setOfferCounts(countOccurancesLastWeek(offers));
        setStateToUpdate("week");
      }
    }
  };

  useEffect(() => {
    setDataSets([
      {
        label: "Researches",
        data: researchCounts,
        backgroundColor: tailwindTheme.theme1,
        order: 1,
      },
      {
        label: "Calls/Emails",
        data: phoneCallCounts,
        backgroundColor: tailwindTheme.theme4,
        order: 2,
      },
      {
        label: "Debt Stacks",
        data: titleCounts,
        backgroundColor: tailwindTheme.theme5,
        order: 3,
      },
      {
        label: "Sequences",
        data: sequenceCounts,
        backgroundColor: tailwindTheme.theme2,
        order: 4,
      },
      {
        label: "Offers",
        data: offerCounts,
        backgroundColor: tailwindTheme.theme3,
        order: 5,
      },
    ]);
  }, [
    properties,
    phoneCalls,
    debtStacks,
    sequences,
    offers,
    stateToUpdate,
    researchCounts,
    phoneCallCounts,
    titleCounts,
    sequenceCounts,
    offerCounts,
    tailwindTheme,
  ]);

  return (
    <>
      <div className="card h-full w-full bg-card-light px-1 pb-1 pt-2 shadow-lg dark:bg-card-dark">
        {properties && phoneCalls && debtStacks && sequences && offers && (
          <>
            <div className="mb-1 lg:px-2">
              <Bar options={chartOptions} data={chartData} />
            </div>
            <div className="mb-0.5 flex flex-row items-center justify-between xs:justify-center xs:gap-3 xs:py-2">
              <button
                onClick={() => handleTimeChange("twelveMonths")}
                className={
                  twelveMonths
                    ? "btn-secondary btn-xs btn text-xxs text-text-light sm:btn-sm hover:bg-secondary dark:text-text-dark"
                    : "btn-ghost btn-xs btn text-xxs text-secondary sm:btn-sm hover:bg-blue-100 hover:dark:bg-back-dark"
                }
              >
                12 Months
              </button>
              <button
                onClick={() => handleTimeChange("sixMonths")}
                className={
                  sixMonths
                    ? "btn-secondary btn-xs btn text-xxs text-text-light sm:btn-sm hover:bg-secondary dark:text-text-dark"
                    : "btn-ghost btn-xs btn text-xxs text-secondary sm:btn-sm  hover:bg-blue-100 hover:dark:bg-back-dark"
                }
              >
                6 Months
              </button>
              <button
                onClick={() => handleTimeChange("twelveWeeks")}
                className={
                  twelveWeeks
                    ? "btn-secondary btn-xs btn text-xxs text-text-light sm:btn-sm hover:bg-secondary dark:text-text-dark"
                    : "btn-ghost btn-xs btn text-xxs text-secondary sm:btn-sm hover:bg-blue-100 hover:dark:bg-back-dark"
                }
              >
                12 Weeks
              </button>
              <button
                onClick={() => handleTimeChange("sixWeeks")}
                className={
                  sixWeeks
                    ? "btn-secondary btn-xs btn text-xxs text-text-light sm:btn-sm hover:bg-secondary dark:text-text-dark"
                    : "btn-ghost btn-xs btn text-xxs text-secondary sm:btn-sm hover:bg-blue-100 hover:dark:bg-back-dark"
                }
              >
                6 Weeks
              </button>
              <button
                onClick={() => handleTimeChange("week")}
                className={
                  week
                    ? "btn-secondary btn-xs btn text-xxs text-text-light sm:btn-sm hover:bg-secondary dark:text-text-dark"
                    : "btn-ghost btn-xs btn text-xxs text-secondary sm:btn-sm hover:bg-blue-100 hover:dark:bg-back-dark"
                }
              >
                1 Week
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ActionStatistics;
