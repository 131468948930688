import { useEffect, useState, ChangeEvent } from "react";
import TeamRow from "src/components/teams/TeamRow";
import { capitalize } from "src/helpers/parseStrings";
import useAdminContext from "src/hooks/private/useAdminContext";
import useViewport from "src/hooks/useViewport";
import FuseCoin from "src/components/icons/FuseCoin";
import TeamsToolbar from "src/components/teams/ToolBarFilter";
import moment from "moment";
import useAdminRedirect from "src/hooks/private/useAdminRedirect";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

interface Team {
  coinsPurchased: number;
  coinsSubscription: number;
  companyName: string;
  createdAt: string;
  email: string;
  id: number;
  returnPhoneNumber: string;
  hasFailedPaymentsDays: boolean;
  hasFailedPayments: number;
  isDemo: boolean;
  isVacation: boolean;
  isActive: boolean;
  subscriptionID: number;
  subscriptionName: string;
  isStaff: boolean;
  appName: string;
}

interface defaultFormDataType {
  id: string;
  companyName: string;
  phoneNumber: string;
  email: string;
  subscription: string;
}

const Teams = () => {
  const { teams } = useAdminContext();
  const { isMobile } = useViewport();
  const location = useLocation();

  const [teamsList, setTeamsList] = useState(teams);
  const [filteredTeamsList, setFilteredTeamsList] = useState<Team[]>([]);

  const defaultData: defaultFormDataType = {
    id: "",
    companyName: "",
    phoneNumber: "",
    email: "",
    subscription: "-",
  };
  const [formData, setFormData] = useState<defaultFormDataType>(defaultData);

  const initialCheckedItems = {
    active: false,
    demo: false,
    vacation: false,
    payments: false,
    staff: false,
    recent: false,
  };

  // Parse the query parameters and update the initialCheckedItems
  const queryParams: any = new URLSearchParams(location.search);

  for (const [key, value] of queryParams) {
    if (key in initialCheckedItems) {
      initialCheckedItems[key as keyof typeof initialCheckedItems] =
        value === "true";
    }
  }

  const [checkedItems, setCheckedItems] = useState(initialCheckedItems);

  const handleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "companyName") {
      setTeamsList(
        teams?.filter((team: Team) =>
          team.companyName.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else if (name === "phoneNumber") {
      setTeamsList(
        teams?.filter((team: Team) => team.returnPhoneNumber.includes(value))
      );
    } else if (name === "id") {
      setTeamsList(
        teams?.filter((team: Team) => team.id.toString().includes(value))
      );
    } else if (name === "email") {
      setTeamsList(
        teams?.filter((team: Team) =>
          team.email.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    setTeamsList(teams);
  }, [teams]);

  useEffect(() => {
    const oneWeekAgo = moment().subtract(1, "weeks");

    const applyFilters = (team: Team) => {
      if (checkedItems.active && !team.isActive) return false;
      if (checkedItems.demo && !team.isDemo) return false;
      if (checkedItems.vacation && !team.isVacation) return false;
      if (checkedItems.payments && !team.hasFailedPayments) return false;
      if (checkedItems.staff && !team.isStaff) return false;
      if (checkedItems.recent && moment(team.createdAt).isBefore(oneWeekAgo))
        return false;

      return true;
    };

    const filteredList: Team[] = teamsList?.filter(applyFilters) || [];
    setFilteredTeamsList(filteredList);
  }, [checkedItems, teamsList]);

  const redirectPath = useAdminRedirect();

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  return (
    <>
      <div className="">
        <TeamsToolbar
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          numTeams={filteredTeamsList.length}
        />
        <table
          className={
            isMobile
              ? "table-compact table w-full shadow-xl"
              : "table w-full shadow-xl"
          }
        >
          <thead>
            <tr className="border-b-2 border-secondary text-secondary">
              <th className="select-none rounded-tl-lg bg-card-light text-center dark:bg-card-dark">
                ID
              </th>
              <th className="select-none bg-card-light text-center dark:bg-card-dark">
                Company
              </th>
              <th className="select-none bg-card-light text-center dark:bg-card-dark">
                Phone
              </th>
              <th className="select-none bg-card-light text-center dark:bg-card-dark">
                Email
              </th>
              <th className="select-none bg-card-light text-center dark:bg-card-dark">
                Subscription
              </th>
              <th className="select-none rounded-tr-lg bg-card-light text-center dark:bg-card-dark">
                Balance
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="">
              <td className="bg-white dark:bg-card-dark">
                <input
                  type="text"
                  placeholder="ID"
                  name="id"
                  className="input-bordered input-secondary input input-md w-20 bg-back-light font-semibold text-text-dark placeholder:text-gray-500 dark:bg-back-dark dark:text-text-light dark:placeholder:text-gray-700"
                  onChange={handleSearch}
                  value={formData.id}
                />
              </td>
              <td className="bg-white dark:bg-card-dark">
                <input
                  type="text"
                  placeholder="Search Companies"
                  name="companyName"
                  className="input-bordered input-secondary input input-md w-full bg-back-light font-semibold text-text-dark placeholder:text-gray-500 dark:bg-back-dark dark:text-text-light dark:placeholder:text-gray-700"
                  onChange={handleSearch}
                  value={formData.companyName}
                />
              </td>
              <td className="bg-white dark:bg-card-dark">
                <input
                  type="text"
                  placeholder="Search Phones"
                  name="phoneNumber"
                  className="input-bordered input-secondary input input-md w-full bg-back-light font-semibold text-text-dark placeholder:text-gray-500 dark:bg-back-dark dark:text-text-light dark:placeholder:text-gray-700"
                  onChange={handleSearch}
                  value={formData.phoneNumber}
                />
              </td>
              <td className="bg-white dark:bg-card-dark">
                <input
                  type="text"
                  placeholder="Search Emails"
                  name="email"
                  className="input-bordered input-secondary input input-md w-full bg-back-light font-semibold text-text-dark placeholder:text-gray-500 dark:bg-back-dark dark:text-text-light dark:placeholder:text-gray-700"
                  onChange={handleSearch}
                  value={formData.email}
                />
              </td>
              <td className="bg-white dark:bg-card-dark"></td>
              <td className="bg-card-light dark:bg-card-dark">
                <FuseCoin />
              </td>
            </tr>
            {filteredTeamsList?.map((team: any, index: number) => (
              <TeamRow team={team} index={index} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Teams;
