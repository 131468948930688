import { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import useTheme from "src/hooks/useTheme";
import useViewport from "src/hooks/useViewport";
import { statisticsApi } from "src/api";
import subscriptions, { SubscriptionKeys } from "src/data/Subscriptions";

ChartJS.register(ArcElement, Tooltip, Legend);

const SubscriptionDoughnutChart = () => {
  const { width } = useViewport();

  const { mode, tailwindTheme } = useTheme();

  const [chartDataValues, setChartDataValues] = useState<
    { id: number; count: number; label: string }[]
  >([]);

  const fetchSubscriptionCount = async () => {
    const resp = await statisticsApi.countSubscriptions();
    const processedData = resp.map((item: any) => {
      const id: SubscriptionKeys = item.subscriptionID;
      return {
        id: id,
        count: parseInt(item.count),
        label: subscriptions[id],
      };
    });
    const sortedData = processedData.sort((a: any, b: any) => a.id - b.id);

    setChartDataValues(sortedData);
  };

  useEffect(() => {
    fetchSubscriptionCount();
  }, []);

  const subscriptionColors: Record<SubscriptionKeys, string> = {
    1: tailwindTheme.theme1,
    2: tailwindTheme.theme4,
    3: tailwindTheme.theme5,
    4: tailwindTheme.theme4,
    5: tailwindTheme.theme5,
    6: tailwindTheme.theme5,
  };

  const chartData = {
    labels: chartDataValues.map((data) => data.label),
    datasets: [
      {
        label: "Subscription Type",
        color: "#FFFFFF",
        data: chartDataValues.map((data) => data.count),
        backgroundColor: chartDataValues.map(
          (data: any) => subscriptionColors[data.id as SubscriptionKeys]
        ),
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        labels: {
          color: mode === "dark" ? "#FFFFFF" : "#000000",
          boxWidth: width < 640 ? 12 : 16,
          boxHeight: width < 640 ? 12 : 16,
          font: {
            size: width < 640 ? 10 : width < 1024 ? 12 : 14,
            family: "sans-serif",
          },
        },
      },
      title: {
        display: true,
        text: "Subscription Type",
        color: mode === "light" ? "#7E7D85" : "#ABA9B4",
        padding: 0,
        font: {
          size: width < 640 ? 13 : 17,
          family: "sans-serif",
        },
      },
    },
  };

  return (
    <div className="flex h-full w-full">
      <div className="stats stats-vertical w-72 rounded-br-none rounded-tr-none shadow">
        <div className="stat bg-card-light py-1 dark:bg-card-dark">
          <div className="stat-title text-zinc-600 dark:text-zinc-300">
            Total Teams
          </div>
          <div className="stat-value text-primary">
            {chartDataValues.reduce(
              (partialSum, data) => partialSum + data.count,
              0
            )}
          </div>
        </div>
        {chartDataValues.map((data) => (
          <div
            key={data.id}
            className="stat bg-card-light py-1 dark:bg-card-dark"
          >
            <div className="stat-title text-zinc-600 dark:text-zinc-300">
              {data.label}
            </div>
            <div
              style={{ color: subscriptionColors[data.id as SubscriptionKeys] }}
              className={`stat-value`}
            >
              {data.count}
            </div>
          </div>
        ))}
      </div>
      <div className="card flex h-full w-full flex-row rounded-bl-none rounded-tl-none bg-card-light shadow-lg dark:bg-card-dark">
        <div className="flex flex-grow columns-1 flex-col items-center py-2 md:py-2 lg:py-3 ">
          <div className="mb-3">
            <Doughnut data={chartData} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDoughnutChart;
