import React from 'react';

const NoToken = () => {
    return (
        <div className="border border-primary rounded flex justify-center">
            <div className="text-2xl text-text-dark dark:text-text-light">
                No tokens at the moment.
            </div>
        </div>
    )
}

export default NoToken;