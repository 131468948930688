import { useEffect, useState } from "react";
import useAlert from "src/hooks/useAlert";
import Button from "../buttons/Button";
import useModal from "src/hooks/useModal";
import FloatingInput from "../fields/FloatingInputText";
import AdminApi from "src/api/admin";
import { TeamPageData } from "src/interfaces/TeamPageData";

interface NewSupportTicketModalProps {
  team: TeamPageData;
  fetchSupportTickets: () => void;
}

const NewSupportTicketModal = ({
  team,
  fetchSupportTickets,
}: NewSupportTicketModalProps) => {
  const { id, leaderID } = team.team;

  const { setAlert } = useAlert();

  const initialFormData: {
    userID: number;
    teamID: number;
    subject: string;
    message: string;
  } = {
    teamID: id || 0,
    userID: leaderID || 0,
    subject: "",
    message: "",
  };

  const initialFormErrors = {
    subject: "",
    message: "",
  };

  interface FormErrrors {
    subject: string | null;
    message: string | null;
  }

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState<FormErrrors>(initialFormErrors);

  const validate: any = (data: any) => {
    const errors: {
      subject?: string;
      message?: string;
    } = {};

    if (!data.subject) {
      errors.subject = "A Subject is required";
    }
    if (!data.message) {
      errors.message = "A Message is required";
    }
    return errors;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setFormErrors(initialFormErrors);
    let errors = validate(formData);
    if (errors.subject || errors.message) {
      return setFormErrors(errors);
    } else {
      setShowModal(false);
      setFormErrors(initialFormErrors);
      try {
        const result = await AdminApi.createSupportTicket({
          ...formData,
        });
        if (result) {
          setAlert({
            display: true,
            message: "Support Ticket created",
            type: "success",
          });
          setFormData(initialFormData);
        }
      } catch (err: any) {
        setAlert({
          display: true,
          message: "An error occured creating the support ticket",
          type: "error",
        });
      } finally {
        return fetchSupportTickets();
      }
    }
  };

  const { setShowModal } = useModal();

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div className="flex w-full items-center justify-center px-3">
      <form className="w-full py-2 sm:w-2/3">
        <div className="flex w-full items-center justify-center">
          <div className="my-3 flex w-full flex-col">
            <FloatingInput
              name="subject"
              label="Subject"
              type="text"
              value={formData.subject}
              onChange={handleChange}
              error={formErrors.subject}
            />
          </div>
        </div>
        <div className="my-3 w-full">
          <FloatingInput
            name="message"
            label="Description"
            type="textarea"
            value={formData.message}
            onChange={handleChange}
            error={formErrors.message}
          />
        </div>
        <div className="mt-4 flex items-center justify-center">
          <Button
            className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
            onClick={handleSubmit}
          >
            Create
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NewSupportTicketModal;
