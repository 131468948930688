import {
  PropsWithChildren,
  createContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Offer } from "src/interfaces/offer";
import OfferApi from "src/api/offers";
import useErrorHandler from "src/hooks/private/useErrorHandler";

export interface OfferContextInterface {
  offers: Offer[];
  fetchOffers: () => void;
}

const offerContextDefaults: OfferContextInterface = {
  offers: [],
  fetchOffers: () => {},
};

const OfferContext = createContext<OfferContextInterface>(offerContextDefaults);

interface OfferProviderProps {}

export const OfferProvider = ({
  children,
}: PropsWithChildren<OfferProviderProps>) => {
  const [offers, setOffers] = useState<Offer[]>([]);

  const { handleError } = useErrorHandler();

  const fetchOffers = useCallback(async () => {
    try {
      const offers = await OfferApi.getOffers();
      setOffers(offers);
    } catch (e: any) {
      handleError(e);
    }
  }, [handleError]);

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  return (
    <OfferContext.Provider
      value={{
        offers,
        fetchOffers,
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};

export default OfferContext;
