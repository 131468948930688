import React from "react";
import { useNavigate } from "react-router-dom";
import usePlacesAutocomplete from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import SendFuse from "../../../api/sendfuse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const SearchBar = () => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"]
    }
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e: any): void => {
    setValue(e.target.value);
  };

  const handleSelect = (e: any): void => {
    setValue(e.target.innerHTML, false);
    clearSuggestions()
  };


  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let result = await SendFuse.researchProperty(value);
    if(result.success) {
      navigate(`/properties/${result.data.id}`);
    }
  };

  return (
    <div ref={ref} className='w-full'>
    <div className=" rounded-lg w-96 pr-4 ">
      <div className="bg-gradient-to-r from-gradient3 to-gradient4 shadow-md rounded-lg p-[2px] min-w-full">
        <div className="flex flex-col justify-between h-full rounded-lg p-[.5px]">
          <form onSubmit={handleSubmit}>
            <label className="relative block rounded-lg">
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <FontAwesomeIcon
                  className="text-secondary"
                  icon={faMagnifyingGlass}
                />
              </span>
              <input
                type="text"
                name="search"
                placeholder="Search for anything..."
                className="min-w-full placeholder:italic bg-back-light font-medium dark:bg-back-dark w-64 h-8 pl-9 focus:outline-none text-slate-dark dark:text-white rounded-md placeholder:text-gray-500 dark:placeholder:text-gray-700"
                value={value}
                onChange={handleInput}
                disabled={!ready}
              />
            </label>
          </form>
        </div>
      </div>
    </div>
    </div>
  );
};

export default SearchBar;
