import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  SubscriptionFeature,
  SubscriptionFeatures,
} from "src/interfaces/subscriptions";
import { Feature as FeatureType } from "src/interfaces/subscriptions";

interface FeaturesProps {
  feature: SubscriptionFeature;
}

function getFeatureNameById(id: number): string {
  switch (id) {
    case 1:
      return "Research";
    case 2:
      return "Debt Stack";
    case 3:
      return "Skip Trace";
    case 4:
      return "Enhanced Skip Trace";
    case 5:
      return "Sequence";
    case 6:
      return "Offer";
    case 7:
      return "Three Tiered Offer";
    default:
      return "Unknown Feature";
  }
}

const Feature = ({ feature }: FeaturesProps) => {
  if(!feature) {
    return null;
  }
  return (
    <>
      {feature.accessType === "coin" && (
        <div className="flex flex-row gap-2 text-[15px]">
          <div>
            <FontAwesomeIcon icon={faCircleCheck} className="text-primary"/>
          </div>
          <div className="text-zinc-700 dark:text-zinc-400">
            {getFeatureNameById(feature.featureID)}
          </div>
        </div>
      )}
    </>
  );
};

interface SubFeaturesProps {
  subFeatures: SubscriptionFeatures;
  features: FeatureType[];
}

const SubFeatures = ({ subFeatures , features }: SubFeaturesProps) => {

  const subFeatureRecord = Object.values(subFeatures || {}).reduce((acc, feature) => {
    acc[feature.featureID] = feature;
    return acc;
  }, {} as Record<number, SubscriptionFeature>);

  return (
    <div className="px-2">
      {features?.map((feature: FeatureType, index: number) => {
        const subscriptionFeature = subFeatureRecord[feature.id];

        const hasFeature = subscriptionFeature?.accessType === "coin";

        return (
          <div key={index} className={hasFeature ? "" : "invisible"}>
            <Feature feature={subscriptionFeature} />
          </div>
        );
      })}
    </div>
  );
};

export default SubFeatures;

