import React from "react";
import { Offer } from "src/interfaces/offer";

interface OffersSentProps {
  offers: Offer[];
}

const OffersSent = ({ offers }: OffersSentProps) => {
  const offersSent = offers.filter((offer: Offer) => offer.isSent);
  const percentSent =
    Math.round((offersSent.length / offers.length) * 100) || 100;

  return (
    <div className="card h-full bg-card-light px-4 shadow-lg">
      <div className="text-[40px] font-bold text-zinc-500">Mailed</div>
      <div className="mb-4 text-xxxl text-zinc-500">
        {offersSent.length} / {offers.length}
      </div>
      <div className="flex w-full items-center justify-center">
        <div
          className="radial-progress bg-zinc-100 text-[40px] font-bold text-primary"
          style={
            {
              "--value": percentSent,
              "--size": "12rem",
              "--thickness": "22px",
            } as React.CSSProperties
          }
        >
          {percentSent}%
        </div>
      </div>
    </div>
  );
};

export default OffersSent;
