import React from "react";
import { Offer } from "src/interfaces/offer";

interface OfferQuantityProps {
  offers: Offer[];
}

const OfferQuantity = ({ offers }: OfferQuantityProps) => {
  return (
    <>
      <div className="card h-1/2 bg-card-light px-4 shadow-lg">
        <div className="text-[40px] font-bold text-zinc-500">Quantity</div>
        <div className="text-[50px] font-bold text-accent">{offers.length}</div>
      </div>
    </>
  );
};

export default OfferQuantity;
