import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CircularActionButtonProps {
  icon: IconProp,
  onClick?: (id?: number | undefined) => void;
  isSmall?: boolean;
}

const CircularActionButton = ({icon, onClick, isSmall}: CircularActionButtonProps) => {

  return (
    <>
    <label
      className={
        isSmall
          ? "btn-xs btn-circle btn cursor-pointer bg-card-dark text-text-light hover:bg-black dark:bg-back-dark  dark:text-gray-500 hover:dark:bg-black"
          : "btn-sm btn-circle btn cursor-pointer bg-card-dark text-text-light hover:bg-black dark:bg-back-dark  dark:text-gray-500 hover:dark:bg-black"
      }
      onClick={() => onClick && onClick()}
    >
      <FontAwesomeIcon
        icon={icon}
        />
    </label>
  </>
  )
};

export default CircularActionButton;