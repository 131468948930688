import React, { useEffect, useState } from "react";
import NewTeamOnboardingModal from "src/components/modals/NewTeamOnboardingModal";
import { Token } from "src/interfaces/token";
import useModal from "src/hooks/useModal";
import AdminApi from "src/api/admin";
import isExpired from "src/helpers/isExpired";
import copyTextToClipboard from "src/helpers/copyTextToClipboard";
import useAdminRedirect from "src/hooks/private/useAdminRedirect";
import { Navigate } from "react-router-dom";

const Onboarding = () => {
  const { openModalWith, setShowModal } = useModal();

  const [tokens, setTokens] = useState<Token[]>([]);

  const fetchTokens = async () => {
    const resp = await AdminApi.getOnboardingTokens();
    setTokens(resp);
  };

  useEffect(() => {
    fetchTokens();
  }, []);

  const handleNewTeam = () => {
    openModalWith({
      title: "Register New Team",
      body: <NewTeamOnboardingModal />,
      hideButtons: true,
    });
  };

  const redirectPath = useAdminRedirect();

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  interface Metadata {
    ifsid: number;
    subscription: "lite" | "pro" | "platinum";
  }

  const handleOnboard = (email: string, metadata: Metadata) => {
    openModalWith({
      title: "Register New Team",
      body: (
        <NewTeamOnboardingModal
          _email={email}
          _keapID={metadata.ifsid}
          _subscription={metadata.subscription}
          callBack={fetchTokens}
        />
      ),
      hideButtons: true,
    });
  };

  const filteredTokens = tokens.filter((token: Token) => !token.consumed);
  return (
    <div>
      <div>
        <button
          className="btn-secondary btn-sm btn mb-2 w-full text-text-light"
          onClick={handleNewTeam}
        >
          + New Team
        </button>
      </div>
      <table className={"block table w-full shadow-lg"}>
        <thead className="">
          <tr className="sticky top-0 border-b-2 border-secondary text-secondary">
            <th className="sticky top-0 rounded-tl-lg bg-card-light dark:bg-card-dark">
              Email
            </th>
            <th className="sticky top-0 rounded-tr-lg bg-card-light dark:bg-card-dark">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredTokens.map((token: Token, index: number) => (
            <tr key={index}>
              <td className="bg-card-light font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
                <button
                  className=" btn-ghost btn-sm btn cursor-pointer normal-case"
                  onClick={() => copyTextToClipboard(token.email)}
                >
                  {token.email}
                </button>
              </td>
              <td className="flex gap-2 bg-card-light font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
                <button
                  className="btn-secondary btn-outline btn-sm btn"
                  onClick={() =>
                    handleOnboard(token.email, JSON.parse(token.metadata))
                  }
                >
                  Complete Onboarding
                </button>
                <a
                  className="btn-primary btn-outline btn-sm btn"
                  target="_blank"
                  rel="noreferrer"
                  href={`https://thesolution.infusionsoft.com/Contact/manageContact.jsp?view=edit&ID=${
                    JSON.parse(token.metadata).ifsid
                  }`}
                >
                  Open Keap
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Onboarding;
