import { useEffect, useState } from "react";
import { Offer } from "src/interfaces/offer";
import { addCommas } from "src/helpers/parseStrings";

interface OfferAveragesProps {
  offers: Offer[];
}

const OfferAverages = ({ offers }: OfferAveragesProps) => {
  const [averageOffers, setAverageOffers] = useState({
    allOffersAverage: 0,
    allCashAverage: 0,
    sellerCarryAverage: 0,
    sellerFinanceAverage: 0,
  });

  useEffect(() => {
    const getAverageOffers = () => {
      let allOfferSum = 0,
        allCashSum = 0,
        sellerCarrySum = 0,
        sellerFinanceSum = 0;
      let allOfferCount = 0,
        allCashCount = 0,
        sellerCarryCount = 0,
        sellerFinanceCount = 0;

      offers.forEach((offer: Offer) => {
        if (offer.offer2 && offer.offer3 && offer.isThreeTiered) {
          allCashSum += offer.offer1;
          allCashCount++;

          sellerCarrySum += offer.offer2;
          sellerCarryCount++;

          sellerFinanceSum += offer.offer3;
          sellerFinanceCount++;

          allOfferSum += offer.offer1 + offer.offer2 + offer.offer3;
          allOfferCount += 3;
        } else {
          allCashSum += offer.offer1;
          allCashCount++;

          allOfferSum += offer.offer1;
          allOfferCount++;
        }
      });

      setAverageOffers({
        allOffersAverage: allOfferCount
          ? Math.round(allOfferSum / allOfferCount)
          : 0,
        allCashAverage: allCashCount
          ? Math.round(allCashSum / allCashCount)
          : 0,
        sellerCarryAverage: sellerCarryCount
          ? Math.round(sellerCarrySum / sellerCarryCount)
          : 0,
        sellerFinanceAverage: sellerFinanceCount
          ? Math.round(sellerFinanceSum / sellerFinanceCount)
          : 0,
      });
    };

    getAverageOffers();
  }, [offers]);
  return (
    <div className="card bg-card-light shadow-xl">
      <div className="px-4 text-[40px] font-bold text-zinc-500">Averages</div>
      <div className="stats stats-vertical bg-card-light">
        <div className="stat py-0">
          <div className="stat-title text-zinc-800">All Offers</div>
          <div className="stat-value text-zinc-400">
            $ {addCommas(averageOffers.allOffersAverage)}
          </div>
        </div>

        <div className="stat py-1.5">
          <div className="stat-title text-zinc-800">All Cash</div>
          <div className="stat-value text-zinc-400">
            $ {addCommas(averageOffers.allCashAverage)}
          </div>
        </div>

        <div className="stat py-1.5">
          <div className="stat-title text-zinc-800">Seller Carry</div>
          <div className="stat-value text-zinc-400">
            $ {addCommas(averageOffers.sellerCarryAverage)}
          </div>
        </div>

        <div className="stat py-1.5">
          <div className="stat-title text-zinc-800">Seller Finance</div>
          <div className="stat-value text-zinc-400">
            $ {addCommas(averageOffers.sellerFinanceAverage)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferAverages;
