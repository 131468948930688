import { Admin } from "src/interfaces/admin";
import { DebtStack } from "src/interfaces/debtStack";
import { Offer } from "src/interfaces/offer";
import { PhoneCall } from "src/interfaces/phoneCall";
import Property from "src/interfaces/property";
import { PropertyUpdate } from "src/interfaces/propertyUpdate";
import { Sequence } from "src/interfaces/sequence";
import { SupportTicket } from "src/interfaces/supportTicket";
import { Team } from "src/interfaces/team";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import { Token } from "src/interfaces/token";
import { RegisterInput, User } from "src/interfaces/user";

class AdminApi extends ApiTokenSupport {
  /**
   * Login
   *
   * @param email string
   * @param password string
   * @returns
   */
  async login(email: string, password: string): Promise<string> {
    const resp = await axios.post<string>("/api/admin/login", {
      email,
      password,
    });
    return resp.data;
  }

  /**
   * Register
   *
   * @param data any
   * @returns
   */
  async register(data: any): Promise<string> {
    const resp = await axios.post<string>("/api/admin/register", { ...data });

    return resp.data;
  }

  /**
   * Recover password
   * @param email
   * @returns
   */
  async recoverPassword(email: string): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/admin/password-recovery`, {
      email,
    });

    return resp.data;
  }

  /**
   * Reset password
   * @param email
   * @returns
   */
  async resetPassword(newPassword: string, token: string): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/admin/password-reset`, {
      newPassword,
      token,
    });

    return resp.data;
  }

  async setPassword(password: string, token: string): Promise<string> {
    const resp = await axios.post<string>(`/api/admin/set-password`, {
      password,
      token,
    });

    return resp.data;
  }

  /**
   * Get admin by ID
   * @param adminID
   * @returns
   */
  async getAdmin(adminID: number): Promise<Admin> {
    const resp = await axios.get<Admin>(
      `/api/admin/${adminID}`,
      this.withAuthorization()
    );

    return resp.data;
  }

  async verifyAdmin(token: string): Promise<Admin> {
    const resp = await axios.get<Admin>(
      `/api/admin/verify`,
      this.withAuthorization({}, token)
    );
    return resp.data;
  }

  async join(token: string, data: {}): Promise<Admin> {
    const resp = await axios.post<Admin>(
      `/api/admin/join`,
      data,
      this.withAuthorization({}, token)
    );
    return resp.data;
  }

  async getTeams(): Promise<any> {
    const resp = await axios.get<any>(
      `/api/admin/teams`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getTeam(teamID: number): Promise<any> {
    const resp = await axios.get<any>(
      `/api/admin/teams/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getTeamNotes(teamID: number): Promise<any> {
    const resp = await axios.get<any>(
      `/api/admin/teams/${teamID}/notes`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async addTeamNote(teamID: number, data: any): Promise<any> {
    const resp = await axios.post<any>(
      `/api/admin/teams/${teamID}/notes`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async editTeamNote(noteID: number, data: any): Promise<any> {
    const resp = await axios.put<any>(
      `/api/teamnote/${noteID}`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async deleteTeamNote(teamID: number, noteID: number): Promise<any> {
    const resp = await axios.delete<any>(
      `/api/admin/teams/${teamID}/notes/${noteID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getTeamTransactions(teamID: number): Promise<any> {
    const resp = await axios.get<any>(
      `/api/admin/teams/${teamID}/transactions`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getTeamUsers(teamID: number): Promise<any> {
    const resp = await axios.get<any>(
      `/api/admin/teams/${teamID}/users`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async updateTeamUsers(userID: number, data: any): Promise<any> {
    const resp = await axios.put<any>(
      `/api/admin/user/${userID}`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async updateTeam(team: Team): Promise<Team> {
    const resp = await axios.put<Team>(
      `/api/admin/team`,
      { team },
      this.withAuthorization()
    );
    return resp.data;
  }

  async updateUser(user: User): Promise<User> {
    const resp = await axios.put<User>(
      `/api/user/${user.id}`,
      user,
      this.withAuthorization()
    );
    return resp.data;
  }

  async addTeamMember(teamID: number, data: {}): Promise<any> {
    const resp = await axios.post<any>(
      `/api/admin/teams/${teamID}/users`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async adjustBalance(data: any): Promise<any> {
    const resp = await axios.post<any>(
      `/api/admin/adjust-balance`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async debitTeam(data: any): Promise<any> {
    const resp = await axios.post<any>(
      `/api/admin/debit-team`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async changeTeamSubscription(data: {
    teamID: number;
    newSubscription: string;
    subscriptionID: number;
    isAnnual?: boolean;
    costToChange?: number;
  }): Promise<any> {
    const resp = await axios.post<string>(
      `/api/admin/teams/change-subscription`,
      data ,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getProperty(propertyID: number): Promise<any> {
    const resp = await axios.get<Property>(
      `/api/admin/properties/${propertyID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getProperties(teamID: number): Promise<Property[]> {
    const resp = await axios.get<Property[]>(
      `api/admin/teams/${teamID}/properties`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getPropertyUpdates(propertyID: number): Promise<PropertyUpdate[]> {
    const resp = await axios.get<PropertyUpdate[]>(
      `api/admin/propertyUpdates/${propertyID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getPhoneCalls(teamID: number): Promise<PhoneCall[]> {
    const resp = await axios.get<PhoneCall[]>(
      `api/admin/phoneCalls/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getSequences(teamID: number): Promise<Sequence[]> {
    const resp = await axios.get<Sequence[]>(
      `api/admin/sequences/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getDebtStacks(teamID: number): Promise<DebtStack[]> {
    const resp = await axios.get<DebtStack[]>(
      `api/admin/debtStacks/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getOffers(teamID: number): Promise<Offer[]> {
    const resp = await axios.get<Offer[]>(
      `api/admin/offers/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getSupportTickets(teamID: number): Promise<SupportTicket[]> {
    const resp = await axios.get<SupportTicket[]>(
      `api/admin/supportTickets/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async createSupportTicket(data: any): Promise<SupportTicket> {
    const resp = await axios.post<SupportTicket>(
      `/api/admin/supportTicket`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async updateSupportTicket(
    supportTicketID: number,
    data: any
  ): Promise<SupportTicket> {
    const resp = await axios.put<SupportTicket>(
      `/api/admin/supportTicket/${supportTicketID}`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async deleteSupportTicket(supportTicketID: number): Promise<any> {
    const resp = await axios.delete(
      `api/admin/supportTicket/${supportTicketID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getAllSupportTickets(): Promise<any> {
    const resp = await axios.get(
      `/api/admin/supportTickets`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getDemoTokens(): Promise<any> {
    const resp = await axios.get(
      `api/admin/tokens/demo-mode`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getOnboardingTokens(): Promise<any> {
    const resp = await axios.get(
      `api/admin/tokens/invite`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getTokens(): Promise<any> {
    const resp = await axios.get(
      `api/admin/tokens/all`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async sendTokenEmail(token: Token): Promise<any> {
    const resp = await axios.post(
      `/api/admin/send-token-email`,
      token,
      this.withAuthorization()
    );
    return resp.data;
  }

  async deleteToken(id: any): Promise<any> {
    const resp = await axios.delete(
      `/api/admin/tokens/${id}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async refreshToken(id: number, days: number): Promise<any> {
    const resp = await axios.post(
      `/api/admin/tokens/refresh`,
      {
        tokenID: id,
        days,
      },
      this.withAuthorization()
    );
    return resp.data;
  }

  async addDemoTeam(data: any): Promise<any> {
    const resp = await axios.post(
      `/api/user/generate-demo-token`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async sendDemoEmail(data: any): Promise<any> {
    const resp = await axios.post(
      `/api/admin/send-demo-email`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async passwordRecovery(email: string): Promise<any> {
    const resp = await axios.post(
      `api/user/password-recovery`,
      { email: email },
      this.withAuthorization()
    );
    return resp.data;
  }

  async setIndefiniteDemo(teamID: number, tokenEmail: string): Promise<Team> {
    const resp = await axios.post<Team>(
      `api/admin/teams/indefinite-demo`,
      { teamID, tokenEmail },
      this.withAuthorization()
    );
    return resp.data;
  }

  async resolveFailedPayment(teamID: number): Promise<any> {
    const resp = await axios.put(
      `api/admin/resolve-failed-payment`,
      { teamID: teamID },
      this.withAuthorization()
    );
    return resp.data;
  }

  async toggleVacationMode(teamID: number): Promise<Team> {
    const resp = await axios.post<Team>(
      `api/admin/teams/toggle-vacation-mode`,
      { teamID },
      this.withAuthorization()
    );
    return resp.data;
  }

  async inviteAdmin(data: any): Promise<any> {
    const resp = await axios.post(
      `api/admin/invite-new-admin`,
      { ...data },
      this.withAuthorization()
    );
    return resp.data;
  }

  async toggleActiveDeactivateAccount(
    teamID: number,
    input: { cancelAtPeriodEnd?: boolean; trial?: number }
  ): Promise<Team> {
    const resp = await axios.post<Team>(
      `api/admin/teams/toggle-active-account`,
      { teamID, ...input },
      this.withAuthorization()
    );
    return resp.data;
  }

  async getUrlToLoginAsTeam(teamId: number): Promise<string> {
    const resp = await axios.get<string>(
      `api/admin/teams/log/${teamId}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getAdmins(): Promise<Admin[]> {
    const resp = await axios.get(`api/admin/admins`, this.withAuthorization());
    return resp.data;
  }

  async deleteAdmin(adminID: number): Promise<any> {
    const resp = await axios.delete(
      `api/admin/${adminID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async update(adminID: number, data: any): Promise<any> {
    const resp = await axios.put(
      `api/admin/${adminID}`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async verifyAdminInvitation(token: string): Promise<Admin> {
    const resp = await axios.get<Admin>(
      `/api/admin/verify-admin-invite?token=${token}`
    );
    return resp.data;
  }

  async getMigrations(): Promise<any> {
    const resp = await axios.get<any>(
      `api/admin/migrations`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async addNewMigration(email: string): Promise<any> {
    const resp = await axios.post<any>(
      `api/admin/add-migration`,
      { email },
      this.withAuthorization()
    );
    return resp.data;
  }

  async emailAllMigrators(): Promise<string> {
    const resp = await axios.post<any>(
      `api/admin/email-migrators`,
      {},
      this.withAuthorization()
    );
    return resp.data;
  }

  async onboardTeam(data: RegisterInput): Promise<any> {
    const resp = await axios.post<any>(
      `api/user/register`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new AdminApi();
