export type SubscriptionKeys = 1 | 2 | 3 | 4 | 5 | 6;
export type Subscriptions = {
  [key in SubscriptionKeys]: string;
};

const subscriptions: Subscriptions = {
  1: "SF Lite",
  2: "SF Pro",
  3: "SF Platinum",
  4: "RS Lite",
  5: "RS Pro",
  6: "RS Platinum",
}

export default subscriptions;