import axios from "axios";
const BASE_URL = process.env.PUBLIC_URL || "http://localhost:3000/api";

class SendFuse {
  static token: any = localStorage.getItem("x-admin-access-token");

  static async request(endpoint: string, data: any, method: any = "get") {
    const url: string = `${BASE_URL}/${endpoint}`;
    const headers: { "x-admin-access-token": string } = {
      "x-admin-access-token": SendFuse.token,
    };
    const params: {} = method === "get" ? data : {};
    console.debug(
      "API Call:",
      "URL=",
      url,
      "Data=",
      data,
      "Method=",
      method,
      "Token=",
      SendFuse.token
    );
    try {
      return (await axios({ url, method, data, params, headers })).data;
    } catch (e: any) {
      console.error("API Error", e.response);
      let message = e.response;
      throw Array.isArray(message) ? message : [message];
    }
  }

  /************************* USER *************************/

  static async login(data: {}) {
    let res: any = await this.request(`admin/login`, data, "post");
    return res;
  }

  static async registerAdmin(data: {}) {
    let res: any = await this.request(`admin/register`, data, "put");
    return res;
  }

  static async addAdmin(data: {}) {
    let res: any = await this.request(`admin`, data, "post");
    return res;
  }

  static async getAdmins() {
    let res: any = await this.request(`admin`, {});
    return res;
  }

  static async getAdmin(adminID: number) {
    let res: any = await this.request(`admin/${adminID}`, {});
    return res;
  }

  /************************* USER SETTINGS *************************/

  // static async getUserSettings() {
  //   let res: any = await this.request(`userSetting`, {});
  //   return res;
  // }

  /************************* TEAM *************************/

  static async register(data: {}) {
    let res: any = await this.request(`team/register`, data, "post");
    return res;
  }

  static async getTeam(teamID: number) {
    let res: any = await this.request(`team/${teamID}`, {});
    return res;
  }

  static async updateTeam(userID: number, data: {}) {
    let res: any = await this.request(`user/${userID}`, data, "put");
    return res;
  }

  /************************* TEAM SETTINGS *************************/

  static async getTeamSettings(teamID: number) {
    let res: any = await this.request(`teamSettings/${teamID}`, {});
    return res;
  }

  static async updateTeamSettings(data: {}) {
    let res: any = await this.request(`teamsetting`, data, "put");
    return res;
  }

  /************************* PROPERTY *************************/

  static async getProperties() {
    let res: any = await this.request(`property`, {});
    return res;
  }

  static async getProperty(propertyID: number) {
    let res: any = await this.request(`property/${propertyID}`, {});
    return res;
  }

  static async researchProperty(data: any) {
    let res: any = await this.request(
      `api/research-property`,
      { address: data },
      "post"
    );
    return res;
  }

  static async toggleWatchList(propertyID: number, data: {}) {
    let res: any = await this.request(`property/${propertyID}`, data, "put");
    return res;
  }

  static async updatePropertyStatus(propertyID: number, data: {}) {
    let res: any = await this.request(`property/${propertyID}`, data, "put");
    return res;
  }

  /************************* LOAN *************************/

  static async getLoans(propertyID: number) {
    let res: any = await this.request(`loan/${propertyID}`, {});
    return res;
  }

  /************************* PROPERTY UPDATES *************************/

  static async getPropertyUpdates() {
    let res: any = await this.request(`propertyUpdate`, {});
    return res;
  }

  /************************* TITLE *************************/

  static async getTitles() {
    let res: any = await this.request(`title`, {});
    return res;
  }

  static async orderTitle(data: {}) {
    let res: any = await this.request(`titles/order`, data, "post");
    return res;
  }

  /************************* SEQUENCE *************************/

  static async createSequence(data: {}) {
    let res: any = await this.request(`sequence`, data, "post");
    return res;
  }

  static async getSequences(teamID: number) {
    let res: any = await this.request(`sequence/all/${teamID}`, {});
    return res;
  }

  /************************* LETTER *************************/

  static async getLetters() {
    let res: any = await this.request(`letter`, {});
    return res;
  }

  /************************* SKIP TRACES *************************/

  static async getSkipTraces() {
    let res: any = await this.request(`skiptrace`, {});
    return res;
  }

  /************************* OFFER *************************/

  static async getOffers() {
    let res: any = await this.request(`offer`, {});
    return res;
  }

  /************************* PHONECALL *************************/

  static async createPhoneCall(data: {}) {
    let res: any = await this.request(`phoneCall`, data, "post");
    return res;
  }

  static async getPhoneCalls() {
    let res: any = await this.request(`phonecall`, {});
    return res;
  }

  /************************* SUPPORT TICKET *************************/

  static async createSupportTicket(data: {}) {
    let res: any = await this.request(`supportTicket`, data, "post");
    return res;
  }

  static async getSupportTickets() {
    let res: any = await this.request(`supportticket`, {});
    return res;
  }

  static async deleteSupportTicket(supportTicketID: number) {
    let res: any = await this.request(
      `supportticket/${supportTicketID}`,
      {},
      "delete"
    );
    return res;
  }

  /************************* STRIPE ACCOUNT *************************/

  static async getStripeAccount() {
    let res: any = await this.request(`stripeAccount`, {});
    return res;
  }

  /************************* EVENT *************************/
  static async getEvents() {
    let res: any = await this.request(`event`, {});
    return res;
  }

  /************************* RSVP *************************/
  static async getRsvps() {
    let res: any = await this.request(`rsvp`, {});
    return res;
  }

  /************************* TRANSACTION *************************/
  static async getTransactions(teamID: number) {
    let res: any = await this.request(`transaction/all/${teamID}`, {});
    return res;
  }

  /************************* TEMPLATE *************************/
  static async getTemplates() {
    let res: any = await this.request(`template`, {});
    return res;
  }
}

export default SendFuse;
