import useAlert from "src/hooks/useAlert";
import { useState } from "react";
import useModal from "src/hooks/useModal";
import AdminApi from "src/api/admin";
import FloatingInput from "../fields/FloatingInputText";
import Button from "../buttons/Button";
import useAdminContext from "src/hooks/private/useAdminContext";
import TeamNote from "src/interfaces/teamNote";

interface NewTeamNoteModalProps {
  teamID: number;
  fetchNotes: () => void;
  teamNote?: TeamNote;
}

const NewTeamNoteModal = ({
  teamID,
  fetchNotes,
  teamNote,
}: NewTeamNoteModalProps) => {
  const { setAlert } = useAlert();

  const { admin } = useAdminContext();

  const { setShowModal } = useModal();

  const initialFormData: {
    note: string;
  } = {
    note: teamNote ? teamNote.note : "",
  };

  const initialFormErrors = {
    note: "",
  };

  interface FormErrors {
    note: string | null;
  }

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const validate: any = (data: any) => {
    const errors: {
      note?: string;
    } = {};
    if (!data.note.length) {
      errors.note = "Please write a note";
    }
    return errors;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setFormErrors(initialFormErrors);
    let errors = validate(formData);
    if (errors.subject || errors.message) {
      return setFormErrors(errors);
    } else {
      setShowModal(false);
      setFormErrors(initialFormErrors);
      try {
        if (teamNote) {
          const result = await AdminApi.editTeamNote(teamNote.id, {
            note: formData.note,
          });
          if (result) {
            setAlert({
              display: true,
              message: "New Team Note Added",
              type: "success",
            });
            setFormData(initialFormData);
          }
        } else {
          const result = await AdminApi.addTeamNote(teamID, {
            author: `${admin?.firstName} ${admin?.lastName}`,
            ...formData,
          });
          if (result) {
            setAlert({
              display: true,
              message: "New Team Note Added",
              type: "success",
            });
            setFormData(initialFormData);
          }
        }
      } catch (err: any) {
        setAlert({
          display: true,
          message:
            err.message || teamNote
              ? "An error occurred editing this Team Note"
              : "An error occurred creating this Team Note",
          type: "error",
        });
      } finally {
        return fetchNotes();
      }
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="mx-4 mb-2">
        <FloatingInput
          name={"note"}
          label={"Note"}
          type={"text"}
          onChange={handleChange}
          value={formData.note}
          error={formErrors.note}
        />
      </div>
      <div className="flex items-center justify-center gap-2">
        <Button
          className="btn-ghost btn w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="btn w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default NewTeamNoteModal;
