import { useEffect, useState } from "react";
import { SupportTicket } from "src/interfaces/supportTicket";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import useViewport from "src/hooks/useViewport";
import NewSupportTicketModal from "../modals/NewSupportTicketModal";
import AdminApi from "src/api/admin";
import { TeamPageData } from "src/interfaces/TeamPageData";
import TeamSupportTicketRow from "../support/TeamSupportTicketRow";

interface TicketsTabProps {
  team: TeamPageData;
}

const TicketsTab = ({ team }: TicketsTabProps) => {
  const { id } = team.team;

  const [supportTickets, setSupportTickets] = useState<SupportTicket[]>([]);

  const { setAlert } = useAlert();

  const { isMobile } = useViewport();

  const { setShowModal, openModalWith } = useModal();

  const fetchSupportTickets = async () => {
    if (id) {
      const response = await AdminApi.getSupportTickets(id);
      setSupportTickets(response);
    }
  };

  useEffect(() => {
    fetchSupportTickets();
  }, [id]);

  const handleDelete = (supportTicketID: number) => {
    openModalWith({
      title: "Delete Support Ticket",
      body: "Are you sure you want to delete this ticket?",
      onSubmit: () => handleDeleteSubmit(supportTicketID),
      submitLabel: "Delete",
    });
  };

  const handleDeleteSubmit = async (supportTicketID: number) => {
    setShowModal(false);
    try {
      await AdminApi.deleteSupportTicket(supportTicketID);
    } catch (err: any) {
      setAlert({
        display: true,
        message: "There was an error deleting your support ticket",
        type: "error",
      });
    } finally {
      return fetchSupportTickets();
    }
  };

  const handleNewSupportTicket = () => {
    if (id) {
      openModalWith({
        title: "Create Support Ticket",
        body: (
          <NewSupportTicketModal
            team={team}
            fetchSupportTickets={fetchSupportTickets}
          />
        ),
        hideButtons: true,
      });
    }
  };

  return (
    <>
      <div>
        <div>
          <button
            className="btn-secondary btn-sm btn mb-2 w-full text-text-light"
            onClick={handleNewSupportTicket}
          >
            + New Support Ticket
          </button>
        </div>
        <table
          className={
            isMobile
              ? "table-compact block table w-full shadow-xl"
              : "block table w-full shadow-xl"
          }
        >
          <thead>
            <tr className="sticky top-0 border-separate border-b-2 border-secondary text-secondary">
              <th className="sticky top-[115px] bg-card-light text-left text-base  dark:bg-card-dark lg:top-0 ">
                Ticket ID
              </th>
              <th className="sticky top-[115px] bg-card-light text-left text-base  dark:bg-card-dark lg:top-0">
                Status
              </th>
              <th className="sticky top-[115px] bg-card-light text-left text-base  dark:bg-card-dark lg:top-0">
                Created
              </th>
              <th className="sticky top-[115px] bg-card-light text-left text-base  dark:bg-card-dark lg:top-0">
                User
              </th>
              <th className="sticky top-[115px] bg-card-light text-left text-base  dark:bg-card-dark lg:top-0">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {supportTickets.length ? (
              supportTickets
                ?.sort(
                  (a: SupportTicket, b: SupportTicket) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
                )
                .map((ticket: SupportTicket) => (
                  <TeamSupportTicketRow
                    supportTicket={ticket}
                    fetchSupportTickets={fetchSupportTickets}
                  />
                ))
            ) : (
              <tr>
                <td
                  className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                  colSpan={6}
                >
                  No Support Tickets for this team yet
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TicketsTab;
