import React, { useEffect, useState } from "react";
import { Token } from "src/interfaces/token";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import useViewport from "src/hooks/useViewport";
import AdminApi from "src/api/admin";
import NewMigrationModal from "src/components/modals/NewMigrationModal";
import { timeUntil } from "src/helpers/parseEpochs";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonLoader from "src/components/loading/ButtonLoader";
import copyTextToClipboard from "src/helpers/copyTextToClipboard";
import useAdminRedirect from "src/hooks/private/useAdminRedirect";
import { Navigate } from "react-router-dom";

const Migration = () => {
  const { isMobile } = useViewport();

  const { setAlert } = useAlert();

  const { openModalWith } = useModal();

  const [migrations, setMigrations] = useState<any[]>([]);
  
  const [isSendingEmails, setIsSendingEmails] = useState<boolean>(false);

  const fetchMigrations = async () => {
    const resp = await AdminApi.getMigrations();
    setMigrations(resp);
  };

  useEffect(() => {
    fetchMigrations();
  }, []);

  const handleNewMigration = () => {
    openModalWith({
      title: "Migrate New Team",
      body: <NewMigrationModal fetchMigrations={fetchMigrations} />,
      hideButtons: true,
    });
  };

  const redirectPath = useAdminRedirect();

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  const handleEmailToken = async (_token: Token) => {
    const { token, expireAt, email } = _token;
    try {
      let resp = await AdminApi.sendDemoEmail({ token, expireAt, email });
      setAlert({
        display: true,
        message: `Demo Mode Invitation sent to ${email}`,
        type: "success",
      });
    } catch (e: any) {
      setAlert({
        display: true,
        message:
          e.message ||
          "An error occurred while sending Demo Mode Invitation email",
        type: "error",
      });
    }
  };


  const handleEmailAll = async () => {
    setIsSendingEmails(true);
    try {
      let resp = await AdminApi.emailAllMigrators();
      console.log(resp);
      setAlert({
        display: true,
        message: "All migrators have been emailed",
        type: "success",
      });
    } catch (e: any) {
      setAlert({
        display: true,
        message:
          e.message || "An error occurred while sending bulk migration emails",
        type: "error",
      });
    } finally {
      setIsSendingEmails(false);
    }
  };

  const isExpired = (timestamp: string) => {
    const timestampNumber = new Date(timestamp).getTime();
    const currentTime = Date.now();
    return currentTime > timestampNumber;
  };

  return (
    <>
      <div>
        <div className="fixed left-0 right-0 flex w-full justify-center gap-1 px-1.5 lg:static lg:px-0">
          <button
            className="btn-secondary btn-sm btn relative mb-2 w-full text-text-light"
            onClick={handleNewMigration}
          >
            + New Migration
          </button>

          {/* <button
            className="btn-secondary btn-sm btn relative mb-2 w-1/2 text-text-light"
            onClick={handleEmailAll}
            disabled={isSendingEmails}
          >
            {isSendingEmails ? <ButtonLoader /> : "Email All"}
          </button> */}
        </div>

        <table
          className={
            isMobile
              ? "table-compact block table w-full shadow-xl"
              : "block table w-full shadow-xl"
          }
        >
          <thead>
            <tr className="sticky top-0 border-separate border-b-2 border-secondary text-secondary">
              <th className="sticky top-[115px] max-w-full whitespace-normal rounded-tl-lg bg-card-light text-base dark:bg-card-dark lg:top-0">
                Email
              </th>
              <th className="sticky top-[115px] bg-card-light text-base  dark:bg-card-dark lg:top-0">
                Expires
              </th>
              <th className="sticky top-[115px] rounded-tr-lg bg-card-light  text-base dark:bg-card-dark lg:top-0">
                Send Email
              </th>
            </tr>
          </thead>
          <tbody className="">
            {migrations.length ? (
              migrations.map((migration: any, index: number) => (
                <tr
                  key={index}
                  className="my-0.5 bg-card-light text-base font-medium text-text-dark dark:bg-card-dark dark:text-text-light"
                >
                  <td className="bg-card-light font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
                    <button
                      className=" btn-ghost btn-sm btn cursor-pointer normal-case"
                      onClick={() => copyTextToClipboard(migration.email)}
                    >
                      {migration.email}
                    </button>
                  </td>
                  <td className="bg-card-light font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
                    {timeUntil(migration.expireAt)}
                  </td>
                  <td className="bg-card-light font-bold text-text-dark dark:bg-card-dark dark:text-text-light">
                    {!isExpired(migration.expireAt) && !migration.consumed && (
                      <button
                        className="btn-accent btn-sm btn gap-1 text-text-light dark:text-text-dark"
                        onClick={() =>
                          handleEmailToken({
                            id: migration.id,
                            token: migration.token,
                            purpose: migration.purpose,
                            expireAt: migration.expireAt,
                            createdAt: migration.createdAt,
                            email: migration.email,
                            consumed: migration.consumed,
                            metadata: migration.metadata,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faPaperPlane} className="" />
                        <div className="font-semibold">Email</div>
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                  colSpan={4}
                >
                  No Migrations yet
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Migration;
