import { useEffect, useState } from "react";
import { timeAgo } from "src/helpers/parseEpochs";
import { capitalize } from "src/helpers/parseStrings";
import useViewport from "src/hooks/useViewport";
import UserAvatar from "../buttons/UserAvatar";
import SwiperInvisibleBlock from "../swiper/SwiperInvisibleBlock";
import SwiperTable from "../swiper/SwiperTable";
import AdminApi from "src/api/admin";
import useAlert from "src/hooks/useAlert";
import ActivityBadge from "../swiper/property/ActivityBadge";
import { TeamPageData } from "src/interfaces/TeamPageData";
import PropertyButton from "../buttons/PropertyButton";
import { Transaction } from "src/interfaces/transaction";

interface TransactionsTabProps {
  team: TeamPageData;
}

const TransactionsTab = ({ team }: TransactionsTabProps) => {
  const [debitTransactions, setDebitTransactions] = useState<Transaction[]>([]);
  const [creditTransactions, setCreditTransactions] = useState<any[]>([]);

  const { isMobile } = useViewport();

  const { setAlert } = useAlert();

  const { id } = team.team;

  const getTransactions = async (type: string) => {
    try {
      if (id) {
        const transactions = await AdminApi.getTeamTransactions(id);
        if (type === "debit") {
          setDebitTransactions(transactions);
        } else {
          setCreditTransactions(transactions);
        }
      }
    } catch (error: any) {
      setAlert({
        display: true,
        message: error?.message ?? "Something went wrong, Please try later.",
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (team) {
      getTransactions("debit");
      getTransactions("credit");
    }
  }, [team]);

  return (
    <SwiperTable
      slides={[
        {
          title: "Debits",
          content: (
            <>
              <table
                className={
                  isMobile
                    ? "table-compact block table w-full shadow-xl"
                    : "block table w-full shadow-xl"
                }
              >
                <thead>
                  <tr
                    style={{
                      position: "static",
                    }}
                    className="border-b-2 border-secondary text-secondary"
                  >
                    <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                      Date
                    </th>

                    {!isMobile && (
                      <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                        Team Member
                      </th>
                    )}
                    <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                      Property
                    </th>
                    <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                      Action
                    </th>
                    <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                      Cost
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {debitTransactions.length ? (
                    debitTransactions?.map(
                      (
                        {
                          type,
                          createdAt,
                          userID,
                          teamID,
                          email,
                          firstName,
                          lastName,
                          fullName,
                          isLeader,
                          key,
                          amount,
                          houseNumber,
                          preDirectional,
                          street,
                          suffix,
                          city,
                          state,
                        },
                        index: number
                      ) =>
                        type === "debit" && (
                          <tr
                            key={index}
                            className="bg-card-light text-text-dark dark:bg-card-dark dark:text-text-light"
                          >
                            <td className="bg-card-light text-center font-semibold dark:bg-card-dark">
                              {timeAgo(createdAt)}
                            </td>
                            {!isMobile && (
                              <td className="bg-card-light text-center dark:bg-card-dark">
                                <div className="flex items-center justify-center">
                                  <UserAvatar
                                    user={{
                                      id: userID,
                                      teamID,
                                      email,
                                      firstName,
                                      lastName,
                                      fullName,
                                      isLeader,
                                    }}
                                  />
                                </div>
                              </td>
                            )}
                            <td
                              className={
                                isMobile
                                  ? "bg-card-light text-center text-xs text-text-dark hover:text-secondary dark:bg-card-dark dark:text-text-light sm:text-base"
                                  : "bg-card-light text-center text-text-dark hover:text-secondary dark:bg-card-dark dark:text-text-light md:text-lg"
                              }
                            >
                              <PropertyButton
                                property={{
                                  houseNumber,
                                  preDirectional,
                                  street,
                                  suffix,
                                  city,
                                  state,
                                }}
                              />
                            </td>
                            <td className="bg-card-light text-center dark:bg-card-dark">
                              <ActivityBadge title={capitalize(key)} />
                            </td>
                            <td className="bg-card-light text-center font-semibold dark:bg-card-dark">
                              {amount > 1
                                ? `${amount} ${isMobile ? "" : "coins"}`
                                : `${amount} ${isMobile ? "" : "coin"}`}
                            </td>
                          </tr>
                        )
                    )
                  ) : (
                    <tr>
                      <td
                        className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                        colSpan={5}
                      >
                        No Debit Transactions for this team yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {debitTransactions && (
                <SwiperInvisibleBlock
                  num={
                    debitTransactions?.filter(
                      (transaction: any) => transaction.type === "debit"
                    ).length
                  }
                />
              )}
            </>
          ),
        },
        {
          title: "Credits",
          content: (
            <>
              <table
                className={
                  isMobile
                    ? "table-compact block table w-full shadow-xl"
                    : "block table w-full shadow-xl"
                }
              >
                <thead>
                  <tr
                    style={{
                      position: "static",
                    }}
                    className="border-b-2 border-secondary text-secondary "
                  >
                    <th className="bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                      Date
                    </th>
                    <th className="bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                      Team Member
                    </th>
                    <th className="bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {creditTransactions.length ? (
                    creditTransactions?.map(
                      (transaction: any, index: number) =>
                        transaction.type === "credit" && (
                          <tr
                            key={index}
                            className="bg-card-light text-text-dark dark:bg-card-dark dark:text-text-light"
                          >
                            <td className="bg-card-light text-center font-semibold dark:bg-card-dark">
                              {timeAgo(transaction.createdAt)}
                            </td>
                            <td className="bg-card-light text-center dark:bg-card-dark">
                              <div className="flex items-center justify-center">
                                <UserAvatar
                                  user={{
                                    id: transaction.userID,
                                    teamID: transaction.teamID,
                                    email: transaction.email,
                                    firstName: transaction.firstName,
                                    lastName: transaction.lastName,
                                    fullName: transaction.fullName,
                                    isLeader: transaction.isLeader,
                                  }}
                                />
                              </div>
                            </td>
                            <td className="bg-card-light text-center font-semibold dark:bg-card-dark">
                              {parseInt(transaction.amount)} coins
                            </td>
                          </tr>
                        )
                    )
                  ) : (
                    <tr>
                      <td
                        className="bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                        colSpan={3}
                      >
                        No Credit Transactions for this team yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {creditTransactions && (
                <SwiperInvisibleBlock
                  num={
                    creditTransactions?.filter(
                      (transaction: any) => transaction.type === "credit"
                    ).length
                  }
                />
              )}
            </>
          ),
        },
      ]}
    />
  );
};

export default TransactionsTab;
