import { useState } from "react";

interface ToolbarItemProps {
  isActive: boolean;
  title: string;
  onClick: (e: any) => void;
}

const ToolbarItem = ({ isActive, title, onClick }: ToolbarItemProps) => {
  return (
    <>
      <div
        className={
          isActive
            ? "tab rounded-md bg-secondary font-bold text-text-light ease-in-out"
            : "tab font-semibold text-icon-light ease-in-out hover:text-text-dark hover:dark:text-text-light"
        }
        onClick={onClick}
      >
        {title}
      </div>
    </>
  );
};

interface TeamToolbarProps {
  isActiveLinks: any;
  handleActive: (str: string) => void;
}

const TeamToolbar = ({ isActiveLinks, handleActive }: TeamToolbarProps) => {
  return (
    <>
      <div className="tabs tabs-boxed border-[.5px] border-black bg-white dark:bg-card-dark">
        <ToolbarItem
          title={"Account"}
          isActive={isActiveLinks["Account"]}
          onClick={() => handleActive("Account")}
        />
        <ToolbarItem
          title={"Team"}
          isActive={isActiveLinks["Team"]}
          onClick={() => handleActive("Team")}
        />
        <ToolbarItem
          title={"Properties"}
          isActive={isActiveLinks["Properties"]}
          onClick={() => handleActive("Properties")}
        />
        <ToolbarItem
          title={"Usage"}
          isActive={isActiveLinks["Usage"]}
          onClick={() => handleActive("Usage")}
        />
        <ToolbarItem
          title={"Tickets"}
          isActive={isActiveLinks["Tickets"]}
          onClick={() => handleActive("Tickets")}
        />
        <ToolbarItem
          title={"Transactions"}
          isActive={isActiveLinks["Transactions"]}
          onClick={() => handleActive("Transactions")}
        />
      </div>
    </>
  );
};

export default TeamToolbar;
