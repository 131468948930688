import React, { useState } from "react";
import useAlert from "src/hooks/useAlert";
import { Permissions } from "src/interfaces/admin";
import useModal from "src/hooks/useModal";
import Button from "../buttons/Button";
import FloatingInput from "../fields/FloatingInputText";
import AdminApi from "src/api/admin";
import FloatingSelect from "../fields/FloatingSelect";
import Toggle from "../buttons/ToggleX";

interface AdminModalProps {
  fetchAdmins: () => Promise<void>;
}

const NewAdminModal = ({ fetchAdmins }: AdminModalProps) => {
  const { setAlert } = useAlert();

  const { setShowModal } = useModal();

  const initialFormData: {
    firstName: string;
    lastName: string;
    email: string;
    securityLevel: number;
  } = {
    firstName: "",
    lastName: "",
    email: "",
    securityLevel: 2,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [onSubmitting, setSubmitting] = useState(false);

  const initialFormErrors = {
    firstName: "",
    lastName: "",
    email: "",
  };

  interface FormErrors {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
  }

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const [permissions, setPermissions] = useState<Permissions>({
    canDashboard: false,
    canTeams: false,
    canAdmins: false,
    canSupport: false,
    canSettings: false,
    canTokens: false,
    canDemo: false,
    canOnboarding: false,
    canMigration: false,
  });

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "securityLevel") {
      setFormData({ ...formData, [name]: parseInt(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleToggleChange = (permissionKey: string) => {
    setPermissions({
      ...permissions,
      [permissionKey]: !permissions[permissionKey],
    });
  };

  const removeCan = (key: string) => {
    return key.split("").slice(3).join("");
  };

  const validate: any = (data: any) => {
    const errors: {
      firstName?: string;
      lastName?: string;
      email?: string;
    } = {};
    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!data.firstName) {
      errors.firstName = "First name is required";
    }
    if (!data.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!data.email) {
      errors.email = "Email is required";
    } else if (!regexEmail.test(data.email)) {
      errors.email = "Email is not a valid format";
    }
    return errors;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setFormErrors(initialFormErrors);
    let errors = validate(formData);
    if (errors.firstName || errors.lastName || errors.email) {
      return setFormErrors(errors);
    } else {
      setFormErrors(initialFormErrors);
      try {
        setSubmitting(true);
        const { firstName, lastName } = formData;
        const result = await AdminApi.inviteAdmin({
          ...formData,
          ...permissions,
        });
        if (result) {
          setAlert({
            display: true,
            message: `${firstName} ${lastName} has been invited`,
            type: "success",
          });
        }
        setFormData(initialFormData);
        setShowModal(false);
        await fetchAdmins();
      } catch (err: any) {
        setAlert({
          display: true,
          message: err?.message || "There was an error inviting this admin",
          type: "error",
        });
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  return (
    <>
      <form>
        <div className="mb-3 flex w-full items-center justify-center">
          <div className="w-full sm:w-2/3">
            <div className="my-3">
              <FloatingInput
                name={"firstName"}
                type={"text"}
                value={formData.firstName}
                onChange={handleFormChange}
                error={formErrors.firstName}
                label={"First Name"}
              />
            </div>
            <div className="my-3">
              <FloatingInput
                name={"lastName"}
                type={"text"}
                value={formData.lastName}
                onChange={handleFormChange}
                error={formErrors.lastName}
                label={"Last Name"}
              />
            </div>
            <div className="my-3">
              <FloatingInput
                name={"email"}
                type={"text"}
                value={formData.email}
                onChange={handleFormChange}
                error={formErrors.email}
                label={"Email"}
              />
            </div>
            <div className="my-3">
              <FloatingSelect
                name="securityLevel"
                title={"Security Level"}
                label={"Security Level"}
                labelProps={{
                  className: "text-text-dark dark:text-text-light",
                }}
                inputProps={{
                  className:
                    "border border-primary bg-back-light dark:bg-base-100 text-text-dark dark:text-text-light rounded-lg",
                }}
                options={[
                  { label: "Owner", value: 1 },
                  { label: "Custom", value: 2 },
                ]}
                value={formData.securityLevel.toString()}
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {formData.securityLevel !== 1 && (
          <div className="flex flex-wrap justify-between">
            {Object.keys(permissions).map((permissionKey) => (
              <div className="w-full p-3 sm:w-1/3" key={permissionKey}>
                <label
                  htmlFor={`new${permissionKey}`}
                  className="block text-text-dark dark:text-text-light"
                >
                  {removeCan(permissionKey)}
                </label>
                <Toggle
                  id={`new${permissionKey}`}
                  title={permissionKey}
                  name={permissionKey}
                  className="toggle-secondary toggle toggle-md"
                  checked={
                    permissions[permissionKey as keyof typeof permissions]
                  }
                  onChange={() => handleToggleChange(permissionKey)}
                />
              </div>
            ))}
          </div>
        )}
        <div className="mt-4 flex items-center justify-center">
          <Button
            className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
            onClick={handleSubmit}
            loading={onSubmitting}
            disabled={onSubmitting}
          >
            Invite
          </Button>
        </div>
      </form>
    </>
  );
};

export default NewAdminModal;
