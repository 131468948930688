/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import classNames from "classnames";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

interface LinkProps {
    to: string;
    className?: string;
}

const Link = ({ to, className, children }: PropsWithChildren<LinkProps> ) => {
    const navigate = useNavigate();
    return (
        <a
            onClick={(e) => navigate(to)}
            className={classNames("link link-hover", className)}
        >
            {children}
        </a>
    )
};

export default Link;
