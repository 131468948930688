import { useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Line } from "react-chartjs-2";
import useTheme from "src/hooks/useTheme";
import useViewport from "src/hooks/useViewport";
import { statisticsApi } from "src/api";
import classNames from "classnames";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface PeriodInterval {
  period: string;
  interval: number;
}

const ChurnChart = () => {
  const { mode, tailwindTheme } = useTheme();

  const { width } = useViewport();

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          color: mode === "dark" ? "#FFFFFF" : "#000000",
          font: {
            size: width < 640 ? 10 : width < 1024 ? 12 : 14,
            family: "sans-serif",
          },
          boxWidth: width < 640 ? 12 : 16,
          boxHeight: width < 640 ? 12 : 16,
        },
      },
      title: {
        display: true,
        text: "Active Subscriptions",
        color: mode === "light" ? "#7E7D85" : "#ABA9B4",
        padding: 0,
        font: {
          size: width < 640 ? 13 : 17,
          family: "sans-serif",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: width < 640 ? 8 : 12,
          },
          color: mode === "dark" ? "#FFFFFF" : "#000000",
        },
      },
      y: {
        ticks: {
          font: {
            size: width < 640 ? 8 : 12,
          },
          color: mode === "dark" ? "#FFFFFF" : "#000000",
        },
      },
    },
  };

  const initialDataSetValue = [
    {
      label: "All",
      data: [],
      borderColor: "#4143BE",
      backgroundColor: "#4143BE",
      order: 0,
    },
    {
      label: "MRR Only",
      data: [],
      borderColor: tailwindTheme?.theme2,
      backgroundColor: tailwindTheme?.theme2,
      order: 1,
    },
    {
      label: "Platinum",
      data: [],
      borderColor: tailwindTheme?.theme5,
      backgroundColor: tailwindTheme?.theme5,
      order: 2,
    },
    {
      label: "Pro",
      data: [],
      borderColor: tailwindTheme?.theme4,
      backgroundColor: tailwindTheme?.theme4,
      order: 3,
    },
    {
      label: "Lite",
      data: [],
      borderColor: tailwindTheme?.theme1,
      backgroundColor: tailwindTheme?.theme1,
      order: 4,
    },
    {
      label: "Vacation",
      data: [],
      borderColor: tailwindTheme?.theme3,
      backgroundColor: tailwindTheme?.theme3,
      order: 5,
    },
    {
      label: "Staff",
      data: [],
      borderColor: "#14FA05",
      backgroundColor: "#14FA05",
      order: 6,
    },
  ];

  const [labels, setLabels] = useState<ChartData<"line">["labels"]>([]);
  const [dataSets, setDataSets] =
    useState<ChartData<"line">["datasets"]>(initialDataSetValue);

  const chartData: ChartData<"line"> = {
    labels,
    datasets: dataSets,
  };

  const [interval, setInterval] = useState<number>(12);

  const handleTimeChange = (interval: number) => {
    setInterval(interval);
  };

  const fetchStats = useCallback(async () => {
    try {
      const { labels, datasets } = await statisticsApi.getChurnStats(interval);

      setLabels(labels);
      const updatedDataSets = dataSets.map((dataSet, index) => {
        switch (index) {
          case 0:
            return {
              ...dataSet,
              data: datasets.map((dataset: any) => dataset.allSubscriptions),
            };
          case 1:
            return {
              ...dataSet,
              data: datasets.map((dataset: any) => dataset.allMRR),
            };
          case 2:
            return {
              ...dataSet,
              data: datasets.map((dataset: any) => dataset.platinum),
            };
          case 3:
            return {
              ...dataSet,
              data: datasets.map((dataset: any) => dataset.pro),
            };
          case 4:
            return {
              ...dataSet,
              data: datasets.map((dataset: any) => dataset.lite),
            };
          case 5:
            return {
              ...dataSet,
              data: datasets.map((dataset: any) => dataset.vacation),
            };
          case 6:
            return {
              ...dataSet,
              data: datasets.map((dataset: any) => dataset.staff),
            };
          default:
            return dataSet;
        }
      });

      setDataSets(updatedDataSets);
    } catch (err: any) {
      console.warn(err?.message);
    }
  }, [interval]);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  const Buttons = useMemo(
    () =>
      [
        { interval: 24, label: "24 Months" },
        { interval: 12, label: "12 Months" },
        { interval: 6, label: "6 Months" },
      ].map((btn) => {
        const active = btn.interval === interval;
        return (
          <button
            key={`${btn.interval}`}
            onClick={() => handleTimeChange(btn.interval)}
            className={classNames("btn btn-xs text-xxs sm:btn-sm", {
              "btn-secondary text-text-light hover:bg-secondary dark:text-text-dark":
                active,
              "btn-ghost text-secondary hover:bg-blue-100 hover:dark:bg-back-dark":
                !active,
            })}
          >
            {btn.label}
          </button>
        );
      }),
    [interval]
  );

  return (
    <>
      <div className="card h-full w-full bg-card-light px-1 pb-1 pt-2 shadow-lg dark:bg-card-dark">
        <div className="mb-1 lg:px-2">
          <Line options={chartOptions} data={chartData} />
        </div>
        <div className="mb-0.5 flex flex-row items-center justify-between xs:justify-center xs:gap-3 xs:py-2">
          {Buttons}
        </div>
      </div>
    </>
  );
};

export default ChurnChart;
