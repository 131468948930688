import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import ButtonLoader from "../loading/ButtonLoader";
import "./Button.css";

export interface ButtonProps {
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  icon?: IconProp;
  onClick?: any;
}

const Button = ({
  className,
  disabled,
  loading,
  children,
  icon,
  onClick,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <button
      className={classNames("btn-custom", className)}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      <div className="flex items-center justify-center">
        <div>
          {icon && !loading && (
            <FontAwesomeIcon
              icon={icon}
              className="text-black text-lg mr-1.5"
            />
          )}
          {loading && <ButtonLoader />}
        </div>
        <div>{children}</div>
      </div>
    </button>
  );
};

export default Button;
