import ButtonLoader from "./ButtonLoader";
import "./ToggleButton.css";

interface ToggleButtonProps {
  isChecked: boolean;
  isLoading: boolean;
  onClick: () => void;
  name: string;
  label: string;
  disabled?: boolean;
}

const ToggleButton = ({
  isChecked,
  isLoading,
  onClick,
  label,
  name,
  disabled,
}: ToggleButtonProps) => {
  return (
    <>
      <label
        htmlFor={name}
        className={
          isChecked
            ? "curser-pointer btn-outline btn btn-sm border-success  text-lg font-bold text-card-dark dark:bg-card-dark text-success"
            : "curser-pointer btn-outline btn btn-sm text-lg font-bold text-zinc-400 hover:border-zinc-400 hover:bg-zinc-300 dark:border-zinc-500 dark:text-zinc-500 dark:hover:bg-zinc-600 dark:hover:text-back-dark"
        }
        onClick={disabled ? () => {} : onClick}
      >
        {label}
        {isLoading ? (
          <div className="ml-5 flex items-center justify-center">
            <ButtonLoader className="h-[21px] w-[21px]" color={"#36D399"} />
          </div>
        ) : (
          <div className="relative">
          <input
            name={name}
            type="checkbox"
            checked={isChecked}
            onChange={() => {}}
            className="sr-only"
            disabled={disabled}
          />
          <div
            className={`box block h-[24px] w-[42px] rounded-full ${
              isChecked ? "bg-primary" : "bg-neutral-400 dark:bg-neutral-500"
            }`}
          ></div>
          <div
            className={`absolute left-[3px] top-[3px] flex h-[18px] w-[18px] items-center justify-center rounded-full bg-white transition ${
              isChecked ? "translate-x-full" : ""
            }`}
          ></div>
        </div>
        )}
      </label>
    </>
  );
};

export default ToggleButton;
