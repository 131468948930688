import { capitalize } from "src/helpers/parseStrings";
import { TeamPageData } from "src/interfaces/TeamPageData";
import { TeamList } from "src/interfaces/team";

interface SubscriptionBadgeProps {
  team: TeamPageData | TeamList;
  error?: boolean;
  days?: number;
  onClick?: () => void;
}

const SubscriptionBadge = ({
  team,
  error,
  days,
  onClick,
}: SubscriptionBadgeProps) => {
  const isTeamPageData = (
    team: TeamPageData | TeamList
  ): team is TeamPageData => {
    return (team as TeamPageData).subscription !== undefined;
  };

  const isStaff = isTeamPageData(team) ? team.team.isStaff : team.isStaff;
  const subscriptionName = isTeamPageData(team)
    ? team.subscription.name
    : team.subscriptionName;

  return (
    <>
      <label
        className={
          error
            ? "relative flex cursor-pointer select-none items-center justify-center rounded-lg border border-error px-2 py-0.5 text-error dark:border-error dark:text-error"
            : "relative flex cursor-pointer select-none items-center justify-center  rounded-lg border border-zinc-500 px-2 py-0.5 text-text-dark dark:border-zinc-500 dark:text-text-light"
        }
        onClick={onClick}
      >
        <h2 className={"mr-1 text-lg font-bold"}>
          {isStaff ? "Staff" : capitalize(subscriptionName)}
        </h2>
        <div className={"m-0 flex flex-col gap-0 p-0"}>
          {isTeamPageData(team) && (
            <span className="font-extralight">
              {team.subscription.sortOrder}/
              {team.appSubscriptionsAndFeatures.length}
            </span>
          )}
        </div>
        {error && days && (
          <div
            className="tooltip-error tooltip absolute -right-4 bottom-3 flex h-[28px] w-[28px] items-center justify-center rounded-full bg-error p-0.5"
            data-tip={`${days} day${
              days > 1 ? "s" : ""
            } since initial failed payment`}
          >
            <span className="font-extrabold text-white dark:text-black">
              {days}
            </span>
          </div>
        )}
      </label>
    </>
  );
};

export default SubscriptionBadge;
