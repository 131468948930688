import { PropsWithChildren, useState } from "react";
import { ModalOptionsProps } from "src/contexts/ModalContext";
import useModal from "src/hooks/useModal";
import Button from "../buttons/Button";
import DeleteButton from "../buttons/DeleteButton";

export const ModalLayout = ({
  children,
  disableOutsideClick,
}: PropsWithChildren<{ disableOutsideClick?: boolean }>) => {
  const { setShowModal } = useModal();

  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.69)",
      }}
      className={
        "fixed top-0 bottom-0 left-0 right-0 bg-title-text-light flex justify-center items-center z-40"
      }
    >
      <div className="flex items-center justify-center w-full">
        <div className="bg-back-light dark:bg-base-100 w-11/12 sm:w-8/12 md:w-7/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12 rounded-lg shadow-lg px-2 pb-6 pt-2 drop-shadow-2xl">
          <div className="flex flex-col columns-1 justify-center">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalCard = ({
  title,
  body,
  onSubmit,
  submitLabel,
  cancelLabel,
  onCancel,
  hideButtons,
}: ModalOptionsProps) => {
  const { setShowModal, showModal } = useModal();

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const handleSubmitWithLoader = async (data: any) => {
    setOnSubmitting(true);
    if (typeof onSubmit === "function") await onSubmit(data);
    setOnSubmitting(false);
  };

  const handleCancel = async () => {
    setShowModal(false);
  };
  
  const onClickCancel = async () => {
    setShowModal(false);
    if(typeof onCancel === "function") {
      onCancel();
    }
  }

  return (
    <>
      {showModal && (
        <ModalLayout>
          <div className="flex items-center justify-between">
            <div className="mb-6 w-5">
            </div>
            <h1 className="text-xxl md:text-xxxl font-semibold select-none text-dark text-secondary mt-4 text-center">
              {title}
            </h1>
            <div className="mb-6">
              <DeleteButton onClick={handleCancel} />
            </div>
          </div>

          <div className="my-4 w-full px-2 text-text-dark dark:text-text-light text-center">
            {body}
          </div>
          {!hideButtons && (
            <div className="flex items-center justify-center">
              <Button
                className="btn btn-ghost text-lg text-text-dark dark:text-text-light hover:bg-blue-200 hover:dark:bg-gray-900 font-bold mr-1 w-32"
                onClick={onClickCancel}
                disabled={onSubmitting}
              >
                {cancelLabel || "Cancel"}
              </Button>
              <Button
                className="btn bg-secondary text-text-light dark:text-text-dark text-lg hover:bg-accent font-bold ml-1 w-32 hover:drop-shadow-lg"
                onClick={handleSubmitWithLoader}
                disabled={onSubmitting}
                loading={onSubmitting}
              >
                {submitLabel || "Submit"}
              </Button>
            </div>
          )}
        </ModalLayout>
      )}
    </>
  );
};

export default ModalCard;
