import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import AdminApi from "src/api/admin";
import TeamToolbar from "src/components/team/TeamToolbar";
import TeamTab from "src/components/team/TeamTab";
import TransactionsTab from "src/components/team/TransactionsTab";
import TicketsTab from "src/components/team/TicketsTab";
import UsageTab from "src/components/team/UsageTab";
import PropertiesTab from "src/components/team/PropertiesTab";
import AccountTab from "src/components/team/AccountTab";
import useAdminRedirect from "src/hooks/private/useAdminRedirect";
import { TeamPageData } from "src/interfaces/TeamPageData";

interface TeamProps {}

const TeamPage = () => {
  const { teamID } = useParams();

  const teamIDNum = teamID && parseInt(teamID);

  const [team, setTeam] = useState<TeamPageData| null>(null);

  const fetchTeam = async () => {
    if (teamIDNum) {
      const response = await AdminApi.getTeam(teamIDNum);
      setTeam(response);
    }
  };


  useEffect(() => {
    fetchTeam();
  }, [teamID, teamIDNum]);

  const [isActiveLinks, setIsActive] = useState({
    Account: true,
    Team: false,
    Properties: false,
    Usage: false,
    Tickets: false,
    Transactions: false,
  });

  const redirectPath = useAdminRedirect();

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  const handleActive = (title: string) => {
    setIsActive({
      Account: false,
      Team: false,
      Properties: false,
      Usage: false,
      Tickets: false,
      Transactions: false,
      [title]: true,
    });
  };

  const AccountTabRender = () => {
    return (
      team && (
        <AccountTab
          team={team}
          fetchTeam={fetchTeam}
          handleActive={handleActive}
        />
      )
    );
  };

  const TeamTabRender = () => {
    return team && <TeamTab team={team} />;
  };

  const PropertiesTabRender = () => {
    return team && <PropertiesTab team={team} />;
  };

  const UsageTabRender = () => {
    return team && <UsageTab team={team} />;
  };

  const TicketsTabRender = () => {
    return team && <TicketsTab team={team} />;
  };

  const TransactionsTabRender = () => {
    return team && <TransactionsTab team={team} />;
  };

  const { Account, Team, Properties, Usage, Tickets, Transactions } =
    isActiveLinks;

  return (
    <>
      {team && (
        <>
          <div className="mb-2">
            <TeamToolbar
              isActiveLinks={isActiveLinks}
              handleActive={handleActive}
            />
          </div>
          <div>
            {Account && AccountTabRender()}
            {Team && TeamTabRender()}
            {Properties && PropertiesTabRender()}
            {Usage && UsageTabRender()}
            {Tickets && TicketsTabRender()}
            {Transactions && TransactionsTabRender()}
          </div>
        </>
      )}
    </>
  );
};

export default TeamPage;
