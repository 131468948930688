import { useState } from "react";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import Button from "../buttons/Button";
import SubscriptionChoice from "../stripe/SubscriptionChoice";
import { TeamPageData } from "src/interfaces/TeamPageData";
import { adminApi } from "src/api";

interface SubscriptionModalProps {
  team: TeamPageData;
  fetchTeam: () => void;
}

const UpgradeModal = ({ team, fetchTeam }: SubscriptionModalProps) => {
  const { setAlert } = useAlert();

  const { setShowModal } = useModal();

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const [selectedSubscription, setSelectedSubscription] = useState<number>(
    team.subscription.id
  );

  const [isAnnual, setIsAnnual] = useState<boolean>(true);

  const handleSelectSub = (subId: number) => {
    setSelectedSubscription(subId);
  };

  const handleSelectPeriod = (annual: boolean) => {
    setIsAnnual(annual);
  };

  const handleSubmit = async () => {
    setOnSubmitting(true);
    try {
      const res = await adminApi.changeTeamSubscription({
        teamID: team.team.id,
        newSubscription: "",
        subscriptionID: selectedSubscription,
        isAnnual,
      });
      console.log(res);
      setAlert({
        type: "success",
        message: "Subscription updated successfully!",
        display: true,
      });
    } catch (error: any) {
      setAlert({
        type: "error",
        message: error?.message || "Error upgrading team subscription",
        display: true,
      });
    } finally {
      setOnSubmitting(false);
      setShowModal(false);
      fetchTeam()
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  return (
    <>
      <div>
        <div>
          <div className="mb-4">
            <SubscriptionChoice
              team={team}
              handleSelectSub={handleSelectSub}
              handleSelectPeriod={handleSelectPeriod}
              selectedSubscription={selectedSubscription}
              isAnnual={isAnnual}
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Button
            className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
            onClick={handleCancel}
            disabled={onSubmitting}
          >
            Cancel
          </Button>
          <Button
            className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
            onClick={handleSubmit}
            disabled={onSubmitting}
            loading={onSubmitting}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default UpgradeModal;
