import React, { useState } from "react";
import { parseMonthDay } from "src/helpers/parseEpochs";
import DeleteButton from "../buttons/DeleteButton";
import TeamButton from "../buttons/TeamButton";
import UserAvatar from "../buttons/UserAvatar";
import TicketStatus from "../fields/TicketStatus";
import AdminApi from "src/api/admin";
import useModal from "src/hooks/useModal";
import useAlert from "src/hooks/useAlert";
import useViewport from "src/hooks/useViewport";
import TicketDrawer from "./SupportTicketDrawer";
import ToggleSupportTicketDetails from "./ToggleSupportTicketDetails";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CircularActionButton from "../buttons/CircularActionButton";


interface SupportTicketRowProps {
  supportTicket: any;
  fetchSupportTickets: () => void;
}

const SupportTicketRow = ({
  supportTicket,
  fetchSupportTickets,
}: SupportTicketRowProps) => {
  const {
    id,
    subject,
    message,
    createdAt,
    companyName,
    userID,
    teamID,
    email,
    firstName,
    lastName,
    fullName,
    isLeader,
  } = supportTicket;
  const { setAlert } = useAlert();

  const { isMobile } = useViewport();

  const { setShowModal, openModalWith } = useModal();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleDelete = (supportTicketID: number) => {
    const handleDeleteSubmit = async (id: number) => {
      try {
        setShowModal(false);
        const result = await AdminApi.deleteSupportTicket(id);
        if (result) {
          setAlert({
            display: true,
            message: "Support Ticket deleted",
            type: "success",
          });
        }
      } catch (err: any) {
        setAlert({
          display: true,
          message: "There was an error deleting your support ticket",
          type: "error",
        });
      } finally {
        return fetchSupportTickets();
      }
    };

    openModalWith({
      title: "Delete Support Ticket",
      body: "Are you sure you want to delete this ticket?",
      onSubmit: () => handleDeleteSubmit(supportTicketID),
      submitLabel: "Delete",
    });
  };

  return (
    <>
      <tr className="bg-card-light dark:bg-card-dark">
        <td className="bg-card-light dark:bg-card-dark text-text-dark dark:text-text-light font-bold text-left w-12">
          {id}
        </td>
        <td className="bg-card-light dark:bg-card-dark text-left">
          <div className="w-full flex items-center justify-start">
            <TicketStatus
              ticket={supportTicket}
              fetchSupportTickets={fetchSupportTickets}
            />
          </div>
        </td>
        <td className="bg-card-light dark:bg-card-dark text-text-dark dark:text-text-light font-bold text-left">
          {parseMonthDay(createdAt)}
        </td>
        <td className="bg-card-light dark:bg-card-dark text-text-dark dark:text-text-light font-bold text-left">
          <TeamButton teamID={teamID} companyName={companyName} />
        </td>
        <td className="bg-card-light dark:bg-card-dark text-text-dark dark:text-text-light font-bold text-left">
          <UserAvatar
            user={{
              id: userID,
              teamID: teamID,
              email: email,
              firstName: firstName,
              lastName: lastName,
              fullName: fullName,
              isLeader: isLeader,
            }}
          />
        </td>
        <td className="bg-card-light dark:bg-card-dark text-left gap-2 flex items-center">
        <CircularActionButton icon={faTrash} onClick={() => handleDelete(id)} />
          <ToggleSupportTicketDetails
            onClick={handleDrawer}
            isToggled={isDrawerOpen}
          />
        </td>
      </tr>
      <tr
        className={
          isDrawerOpen
            ? "relative bg-card-light dark:bg-card-dark duration-300 transition-all "
            : "absolute bg-card-light dark:bg-card-dark  h-0 duration-300 "
        }
      >
        {isDrawerOpen && (
          <td
            colSpan={isMobile ? 4 : 6}
            className="bg-back-light border-x dark:bg-back-dark dark:border-x dark:border-card-dark overflow-x-hidden"
            style={{
              overflowWrap: "break-word",
            }}
          >
            <TicketDrawer subject={subject} message={message} />
          </td>
        )}
      </tr>
    </>
  );
};

export default SupportTicketRow;
