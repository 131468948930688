import React from "react";

interface TopOfferTeamsProps {
  teams: any;
  subTable: string;
  sanitizeFunction?: any;
}

const TopOfferTeams = ({ teams, subTable, sanitizeFunction }: TopOfferTeamsProps) => {
  return (
    <div className="card bg-card-light px-4 shadow-lg">
      <div className="flex flex-row items-center gap-20 text-zinc-500">
        <div className="text-[40px] font-bold">Top Teams</div>
        <span className="text-center text-sm font-normal">{subTable}</span>
      </div>
      <table className="table-compact table ">
        <tbody>
          {teams.map((team: any, index: number) => (
            <tr key={index}>
              <td className="font semibold text-lg text-black">
                {team.companyName}
              </td>
              <td className="text-xxxl font-bold text-secondary">
                {sanitizeFunction ? sanitizeFunction(team.amount) : team.amount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopOfferTeams;
