import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { StripeElementOptions } from "src/contexts/StripeContext";
import useStripeOptions from "src/hooks/useStripeOptions";
import useTheme from "src/hooks/useTheme";

const StripeForm = () => {
  const { mode } = useTheme();

  const options: StripeElementOptions  = {
    classes: {
      base: 'px-3 py-3 placeholder-gray-400 font-bold placeholder:font-normal bg-white dark:bg-back-dark rounded text-sm shadow w-full',
      focus: 'ring ring-purple',
    },
    style: {
      base: {
        color: mode === 'dark' ? 'white' : 'black',
        fontWeight: 'bold',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: mode === 'dark' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
          fontWeight: '400',
        }
      },
    },
  }

  return (
    <div className="flex items-center justify-between gap-2 w-full">
      <div className="w-7/12">
        <label className="mb-1 block text-xs font-extrabold uppercase text-card-dark dark:text-text-light text-left">
          Card Number
        </label>
        <CardNumberElement options={{ ...options, showIcon: true }} />
      </div>
      <div className="w-3/12">
        <label className="mb-1 block text-xs font-extrabold uppercase text-card-dark dark:text-text-light text-left">
          Exp
        </label>
        <CardExpiryElement options={options} />
      </div>
      <div className="w-2/12 text-left">
        <label className="mb-1 block text-xs font-extrabold uppercase text-card-dark dark:text-text-light">
          CVC
        </label>
        <CardCvcElement options={options} />
      </div>
    </div>
  );
};

export default StripeForm
