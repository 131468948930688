import React from "react";
import { Offer } from "src/interfaces/offer";
import { addCommas } from "src/helpers/parseStrings";

interface OfferVolumeProps {
  offers: Offer[];
}

const OfferVolume = ({ offers }: OfferVolumeProps) => {
  const totalVolume = offers.reduce((acc, offer) => {
    if (offer.isThreeTiered && offer.offer1 && offer.offer3) {
      return acc + offer.offer3;
    } else {
      return acc + offer.offer1;
    }
  }, 0);

  return (
    <>
      <div className="card h-1/2 bg-card-light px-4 shadow-lg">
        <div className="text-[40px] font-bold text-zinc-500">Volume </div>
        <div className="text-[50px] font-bold text-primary">
          ${addCommas(totalVolume)}
        </div>
      </div>
    </>
  );
};

export default OfferVolume;
