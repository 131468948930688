import classNames from "classnames";
import { PropsWithChildren } from "react";
import Button, { ButtonProps } from "./Button";

const AuthButton = (props: PropsWithChildren<ButtonProps>) => {
    const buttonClass = classNames(
        "bg-accent text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full",
        props?.className
    )
    return (
        <Button
            className={buttonClass}
            {...props}
          >
            {props?.children}
        </Button>
    );
};

export default AuthButton;