import { PropsWithChildren } from "react";
import { ModalProvider } from "src/contexts/ModalContext";

/***** Context Provider *****/
import { AdminProvider } from "src/contexts/private/AdminContext";

interface PrivateProviderProps {}

const PrivateProvider = ({
  children,
}: PropsWithChildren<PrivateProviderProps>) => {
  return (
    <AdminProvider>
      <ModalProvider>{children}</ModalProvider>
    </AdminProvider>
  );
};

export default PrivateProvider;
