import { TeamPageData } from "src/interfaces/TeamPageData";
import ActionPallette from "../cards/ActionPallette";
import TeamInfo from "../cards/TeamInfo";
import TeamEventsTable from "./TeamEventsTable";
import { useState, useEffect } from "react";
import NotesTable from "../notes/NotesTable";
import AdminApi from "src/api/admin";

interface AccountTabProps {
  team: TeamPageData;
  fetchTeam: () => void;
  handleActive: (title: string) => void;
}

const AccountTab = ({ team, fetchTeam, handleActive }: AccountTabProps) => {
  const { teamID } = team.user;

  const [notes, setNotes] = useState([]);

  const fetchNotes = async () => {
    if (teamID) {
      const response = await AdminApi.getTeamNotes(teamID);
      setNotes(response);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [teamID]);

  return (
    <>
      <div className="mb-2 flex w-full gap-1">
        <div className="w-1/2">
          <div className="mb-1">
            <TeamInfo
              team={team}
              fetchTeam={fetchTeam}
              handleActive={handleActive}
              fetchNotes={fetchNotes}
            />
          </div>
          <TeamEventsTable team={team} />
        </div>
        <div className="w-1/2">
          <div className="mb-1">
            <ActionPallette team={team} fetchTeam={fetchTeam} />
          </div>
          <NotesTable team={team} notes={notes} fetchNotes={fetchNotes} />
        </div>
      </div>
    </>
  );
};

export default AccountTab;
