import { useState } from "react";

import "./Login.css";

import useAuth from "src/hooks/useAuth";
import TextInput from "src/components/fields/TextInput";
import Link from "src/components/links/Link";
import AuthLayout from "src/layouts/AuthLayout";
import { regexEmail } from "src/helpers/constants";
import AuthButton from "src/components/buttons/AuthButton";
import PasswordInput from "src/components/fields/PasswordInput";

const Login = () => {
  const initialFormState = {
    email: "",
    password: "",
  };

  const intialFormErrors: {
    email: string;
    password: string;
  } = {
    email: "",
    password: "",
  }

  interface FormErrors {
    email: string | null;
    password: string | null;
  }

  const [formData, setFormData] = useState(initialFormState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>(intialFormErrors);

  const { login } = useAuth();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate: any = (data: any) => {
    const errors: {
      email?: string,
      password?: string,
    } = {};
    if (!data.email) {
      errors.email = "Email is required";
    } else if (!regexEmail.test(data.email)) {
      errors.email = "Email is not a valid format";
    }
    if (!data.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    let errors = validate(formData);
    if (errors.email || errors.password) {
      return setFormErrors(errors);
    } else {
      const { email, password } = formData;
      setSubmitting(true);
      await login(email, password);
      setSubmitting(false);
    }
  }

  const bottomComponent = (
    <div className="flex flex-wrap mt-6">
      <div className="w-1/2">
        <Link
          className="text-card-dark dark:text-card-light"
          to="/forgot-password"
        >
          <small>Forgot password?</small>
        </Link>
      </div>
      <div className="w-1/2 text-right">
        <Link
          className="text-card-dark dark:text-card-light "
          to="/register"
        >
          <small>Create new account</small>
        </Link>
      </div>
    </div>
  )

  return (
    <AuthLayout title="SendFuse Admin" >
      <form onSubmit={handleSubmit}>
        <div className="relative w-full mb-3">
          <TextInput
            name="email"
            label="Email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            error={formErrors.email}
          />
        </div>

        <div className="relative w-full mb-3">
          <PasswordInput
            label="Password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            error={formErrors.password}
          />
        </div>
        <div>
          <label className="inline-flex items-center cursor-pointer">
            <input
              id="customCheckLogin"
              type="checkbox"
              className="form-checkbox border-0 rounded ml-1 w-5 h-5"
            />
            <span className="ml-2 text-sm font-semibold text-card-dark">
              Remember Me
            </span>
          </label>
        </div>

        <div className="text-center mt-6">
          <AuthButton
            onClick={handleSubmit}
            loading={submitting}
            disabled={submitting}
          >
            Login
          </AuthButton>
        </div>
      </form>
    </AuthLayout>
  )
};

export default Login;
