import React from "react";

interface DeleteButtonProps {
  onClick?: (id?: number | undefined) => void;
}

const DeleteButton = ({ onClick }: DeleteButtonProps) => {
  return (
    <>
      <label
        className="btn btn-circle btn-sm bg-card-dark dark:bg-back-dark text-text-light hover:bg-black hover:dark:bg-black  dark:text-gray-500 cursor-pointer "
        onClick={() => onClick && onClick()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </label>
    </>
  );
};

export default DeleteButton;
