import React from "react";


const Reports = () => {
  return (
    <div>
      <h1>Reports</h1>
    </div>
  );
};

export default Reports;