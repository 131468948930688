import { Navigate } from "react-router-dom";
import ActionStatistics from "src/components/dashboard/ActionStatsChart";
import AverageOfferAmount from "src/components/dashboard/AverageOfferAmount";
import ChurnChart from "src/components/dashboard/ChurnChart";
import RevenueTypeDoughnutChart from "src/components/dashboard/RevenueTypeDoughnutChart";
import SubscriptionDoughnutChart from "src/components/dashboard/SubscriptionDoughnutChart";
import useAdminRedirect from "src/hooks/private/useAdminRedirect";

const Dashboard = () => {

  const redirectPath = useAdminRedirect();

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }
  
  return (
    <>
      <div>
        <div className="mb-1 flex w-full gap-1">
          <div
            style={{
              width: "39%",
            }}
            className=""
          >
            <RevenueTypeDoughnutChart />
          </div>
          <div
            style={{
              width: "39%",
            }}
            className=""
          >
            <SubscriptionDoughnutChart />
          </div>
          <div
            style={{
              width: "22%",
            }}
            className=""
          >
            <AverageOfferAmount />
          </div>
        </div>
        <div className="w-full mb-1">
          <ActionStatistics />
        </div>
        <div className="w-full">
          <ChurnChart />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
