import {
  faPhone,
  faEnvelope,
  faCreditCard,
  faKey,
  faIdBadge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ToggleButton from "../buttons/ToggleButton";
// import ButtonModal from "../modals/ButtonModal";
import "src/css/removeNumberInputArrows.css";
import useAlert from "src/hooks/useAlert";
import { TeamPageData } from "src/interfaces/TeamPageData";
import admin from "src/api/admin";
import "src/css/removeNumberInputArrows.css";
import ReactivateTeamModal from "../modals/ReactivateTeamModal";
import useModal from "src/hooks/useModal";

interface ActionPalletteProps {
  team: TeamPageData;
  fetchTeam: () => void;
}

const ActionPallette = ({ team, fetchTeam }: ActionPalletteProps) => {
  const { setAlert } = useAlert();
  const { setShowModal, openModalWith } = useModal();

  const { teamID, email } = team.user;
  const { companyName, isDemo, isVacation, isActive, keapID } = team.team;
  const { returnPhoneNumber } = team.teamSettings;
  const { customerID } = team.stripeAccount;

  const [isCheckedDemo, setIsCheckedDemo] = useState(isDemo);
  const [isCheckedVacation, setIsCheckedVacation] = useState(isVacation);
  const [isCheckedActive, setIsCheckedActive] = useState(isActive);
  const [isLoadingDemo, setIsLoadingDemo] = useState(false);
  const [isLoadingVacation, setIsLoadingVacation] = useState(false);
  const [isLoadingActive, setIsLoadingActive] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(false);

  const handleDemo = async () => {
    setIsLoadingDemo(true);
    try {
      const result = await admin.setIndefiniteDemo(teamID, email);
      setIsCheckedDemo(!isCheckedDemo);
      const message = result.isDemo
        ? `${companyName} has been placed in Demo Mode indefinitely`
        : `Indefinite Demo Mode has been disabled for ${companyName}`;
      setAlert({
        display: true,
        type: "success",
        message,
      });
    } catch (e: any) {
      setAlert({
        display: true,
        type: "error",
        message: e?.message || "There was an issue when updating the Demo Mode",
      });
    } finally {
      setIsLoadingDemo(false);
      setShowModal(false);
      fetchTeam();
    }
  };

  const handleVacation = async () => {
    setIsLoadingVacation(true);
    try {
      const result = await admin.toggleVacationMode(teamID);
      setIsCheckedVacation(!isCheckedVacation);
      const message = result.isVacation
        ? `${companyName} has been placed in Vacation Mode`
        : `Vacation Mode has been disabled for ${companyName}`;
      setAlert({
        display: true,
        type: "success",
        message,
      });
    } catch (e: any) {
      setAlert({
        display: true,
        type: "error",
        message:
          e?.message ||
          "There was an issue placing this account in Vacation Mode",
      });
    } finally {
      setIsLoadingVacation(false);
      fetchTeam();
    }
  };

  const handleActive = async (input?: {
    cancelAtPeriodEnd?: boolean;
    trial?: number;
  }) => {
    setIsLoadingActive(true);
    try {
      // Call to backend to change Active
      const result = await admin.toggleActiveDeactivateAccount(teamID, {
        ...input,
      });
      setIsCheckedActive(!isCheckedActive);
      const message = !result.isActive
        ? `${companyName} has been deactivated`
        : `${companyName} has been re-activated`;
      setAlert({
        display: true,
        type: "success",
        message,
      });
    } catch (e: any) {
      setAlert({
        display: true,
        type: "error",
        message: e?.message || "There was an issue deactivating this account",
      });
    } finally {
      setShowModal(false);
      setIsLoadingActive(false);
      fetchTeam();
    }
  };

  const confirmActivateDeactivateAccount = () => {
    if (!isActive) {
      openModalWith({
        title: "Reactivate Team",
        body: (
          <ReactivateTeamModal
            onSubmit={(trial: number) => handleActive({ trial })}
          />
        ),
        hideButtons: true,
      });
    } else {
      openModalWith({
        title: "Deactivate This Account",
        body: (
          <div className="text-center">
            <p className="text-text-dark dark:text-text-light">
              Do you want to cancel the subscription at the end of the current
              billing period?
            </p>
          </div>
        ),
        onSubmit: () => handleActive({ cancelAtPeriodEnd: true }),
        onCancel: () => handleActive(),
        cancelLabel: "No",
        submitLabel: "Yes",
        helpBody: (
          <p>
            This will for the duration of time the customer has already paid
            for, or you can charge and cancel immediately the subscription.
          </p>
        ),
      });
    }
  };

  const handleSendFuse = async () => {
    try {
      setIsLoadingToken(true);
      const url = await admin.getUrlToLoginAsTeam(teamID);
      // const url = `${process.env.REACT_APP_FRONTEND_URL}/log-as-user/${token}`;
      window.open(url, "_blank");
    } catch (e: any) {
      setAlert({
        type: "error",
        message: e?.message,
        display: true,
      });
    } finally {
      setIsLoadingToken(false);
    }
  };

  return (
    <>
      <div className="card w-full rounded-lg bg-white px-6 py-4 shadow-lg dark:bg-card-dark">
        <div className="mb-2.5 flex items-center justify-between">
          <ToggleButton
            isChecked={isCheckedDemo}
            name="demo-modal"
            label="Demo"
            isLoading={isLoadingDemo}
            onClick={handleDemo}
          />

          <ToggleButton
            name="vacation-modal"
            isChecked={isCheckedVacation}
            label={"Vacation"}
            isLoading={isLoadingVacation}
            onClick={handleVacation}
          />

          <ToggleButton
            name="active-modal"
            isChecked={isCheckedActive}
            label={"Active"}
            isLoading={isLoadingActive}
            onClick={confirmActivateDeactivateAccount}
          />
        </div>
        <div className="flex items-center justify-between gap-1">
          <a
            target="_blank"
            rel="noreferrer"
            href={`tel:${returnPhoneNumber}`}
            className="w-full"
          >
            <button className="btn-sm btn w-full border-none bg-theme4 text-text-light @container hover:bg-theme4 dark:text-black dark:text-text-dark">
              <span className="text-xxs @[50px]:text-xs @[70px]:text-sm">
                <FontAwesomeIcon icon={faPhone} className=" mr-1" />
                Call
              </span>
            </button>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`mailto:${email}`}
            className="w-full"
          >
            <button className="btn-sm btn w-full border-none bg-theme3 text-text-light @container hover:bg-theme3 dark:text-black dark:text-text-dark">
              <span className="text-xxs @[50px]:text-xs @[70px]:text-sm">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className=" text-md childButton mr-1"
                />
                Email
              </span>
            </button>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://dashboard.stripe.com/customers/${customerID}`}
            className="w-full"
          >
            <button className="btn-sm btn w-full border-none bg-theme5 text-text-light @container hover:bg-theme5 dark:text-black dark:text-text-dark">
              <span className="text-xxs @[50px]:text-xs @[70px]:text-sm">
                <FontAwesomeIcon
                  icon={faCreditCard}
                  className="text-md childButton mr-1"
                />
                Stripe
              </span>
            </button>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://thesolution.infusionsoft.com/Contact/manageContact.jsp?view=edit&ID=${keapID}`}
            className="w-full"
          >
            <button className="btn-sm btn w-full border-none bg-theme2 text-text-light @container hover:bg-theme2 dark:text-text-dark">
              <span className="text-xxs @[50px]:text-xs @[70px]:text-sm">
                <FontAwesomeIcon
                  icon={faKey}
                  className=" text-md childButton mr-1"
                />
                Keap
              </span>
            </button>
          </a>
          <div className="w-full">
            <button
              onClick={handleSendFuse}
              disabled={isLoadingToken}
              className="btn-sm btn w-full border-none bg-theme1 text-text-light @container hover:bg-theme1 dark:text-text-dark"
            >
              <span className="text-xxs @[50px]:text-xs @[70px]:text-sm">
                <FontAwesomeIcon
                  icon={faIdBadge}
                  className=" text-md childButton mr-1"
                />
                Login
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionPallette;
